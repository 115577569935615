// @flow
import * as React from 'react'

const Guardianship = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.guardianship-cls-1,.guardianship-cls-2{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:3.1px}.guardianship-cls-1{stroke:#cfe0d5}.guardianship-cls-2{stroke:#d4cbe0}.guardianship-cls-5{fill:#5e4876}.guardianship-cls-6{fill:#3e8358}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <path
        d="M0 0h198v214.4H0z"
        style={{
          fill: '#0e642e',
        }}
      />
      <path d="M85.4 98.6h28v25.8h-28z" className="guardianship-cls-6" />
      <path
        d="M115.7 103.5c6.5 0 7.1-7.1 7.1-7.1V78.7c0-10.4-6.4-19.2-15.3-22.5-1.9 1.9-4.5 3.1-7.4 3.1h.1c-2.9 0-5.5-1.2-7.4-3.1-8.9 3.3-15.3 12.1-15.3 22.5v17.7s.7 7.1 7.1 7.1"
        className="guardianship-cls-6"
      />
      <circle
        cx={100.1}
        cy={39.8}
        r={10.7}
        style={{
          fill: '#3e8358',
          stroke: '#cfe0d5',
          strokeWidth: '3.1px',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M84.3 98.7v25.8M116.3 103.7c6.6 0 7.3-7.3 7.3-7.3V78.2c0-10.7-6.6-19.8-15.7-23.1-1.9 2-4.6 3.2-7.6 3.2S94.6 57 92.6 55M114.8 98.7v25.8M99.4 108v16.5M92.3 55c-9.1 3.4-15.7 12.4-15.7 23.1v18.2s.8 7.1 7.4 7.1"
        className="guardianship-cls-1"
      />
      <path
        d="M64.1 177.6 65 164c.2-2.8-.8-5.6-2.7-7.7l-25.9-27.8c-.8-.9-1.3-2.1-1.3-3.3l.2-44.6c0-1.3 1.2-2.4 2.5-2.2 5.5.8 9.9 3.8 10.8 11.9.6 5.9 1.4 13.7 1.9 18.2l.6 4.9H51c4.4-1.1 7.5 1.5 8.5 2.6l9 9.7c7.2 1.7 10.5 3.9 15.4 9.2 5.9 6.3 7.6 14.2 5.5 28.1v14.7"
        className="guardianship-cls-5"
      />
      <path
        d="M50.3 108.8c-.5-4.5-1.3-12.3-1.9-18.2-.9-8.1-5.3-11.1-10.8-11.9-1.3-.2-2.5.8-2.5 2.2l-.2 44.6c0 1.2.5 2.4 1.3 3.3l25.9 27.8c1.9 2.1 2.9 4.8 2.7 7.7l-.9 13.6"
        className="guardianship-cls-2"
      />
      <path
        d="m63.8 137.7-18-19.3c-.6-.6-.5-1.5.1-2 7-5.6 12-1.5 13.4 0l9 9.7c7.2 1.7 10.5 3.9 15.4 9.2 5.9 6.3 7.6 14.2 5.5 28.1v14.7"
        className="guardianship-cls-2"
      />
      <path
        d="m136.1 177.7-.9-13.6c-.2-2.8.8-5.6 2.7-7.7l25.9-27.8c.8-.9 1.3-2.1 1.3-3.3l-.2-44.6c0-1.3-1.2-2.4-2.5-2.2-5.5.8-9.9 3.8-10.8 11.9-.6 5.9-1.4 13.7-1.9 18.2l-.6 4.9h.1c-4.4-1.1-7.5 1.5-8.5 2.6l-9 9.7c-7.2 1.7-10.5 3.9-15.4 9.2-5.9 6.3-7.6 14.2-5.5 28.1v14.7"
        className="guardianship-cls-5"
      />
      <path
        d="M149.9 108.9c.5-4.5 1.3-12.3 1.9-18.2.9-8.1 5.3-11.1 10.8-11.9 1.3-.2 2.5.8 2.5 2.2l.2 44.6c0 1.2-.5 2.4-1.3 3.3l-25.9 27.8c-1.9 2.1-2.9 4.8-2.7 7.7l.9 13.6"
        className="guardianship-cls-2"
      />
      <path
        d="m136.4 137.7 18-19.3c.6-.6.5-1.5-.1-2-7-5.6-12-1.5-13.4 0l-9 9.7c-7.2 1.7-10.5 3.9-15.4 9.2-5.9 6.3-7.6 14.2-5.5 28.1v14.7"
        className="guardianship-cls-2"
      />
    </g>
  </svg>
)

export default Guardianship
