// @flow
import React from 'react'
import type { LifeSituationLinkViewModel } from '../../types/LifeSituationLinkViewModel'
import { rem } from 'polished'
import { Link } from '../Link/Link'
import styled from 'styled-components'
import AddressesAndOpeningHours from '../../icons/AddressesAndOpeningHours'
import Statistics from '../../icons/Statistics'
import DivorceSeparation from '../../icons/DivorceSeparation'
import Guardianship from '../../icons/Guardianship'
import AnnualReports from '../../icons/AnnualReports'
import Recognitions from '../../icons/Recognitions'
import Restraint from '../../icons/Restraint'
import ChildResidence from '../../icons/ChildResidence'
import PaymentOfFee from '../../icons/PaymentOfFee'
import Contribution from '../../icons/Contribution'
import ChildSupport from '../../icons/ChildSupport'
import PaternityCoMotherhood from '../../icons/PaternityCoMotherhood'
import MovingWithoutConsent from '../../icons/MovingWithoutConsent'
import ParentalResponsibility from '../../icons/ParentalResponsibility'
import FuturePowerOfAttorney from '../../icons/FuturePowerOfAttorney'
import InternationalMarriages from '../../icons/InternationalMarriages'
import JobsAtTheFamilyCourt from '../../icons/JobsAtTheFamilyCourt'
import Complaint from '../../icons/Complaint'
import MeetingsAtTheFamilyCourt from '../../icons/MeetingsAtTheFamilyCourt'
import Names from '../../icons/Names'
import AboutTheFamilyCourt from '../../icons/AboutTheFamilyCourt'
import Organization from '../../icons/Organization'
import PerformancePlans from '../../icons/PerformancePlans'
import HowToContactUs from '../../icons/HowToContactUs'
import CaseProcessingTimes from '../../icons/CaseProcessingTimes'
import Visitation from '../../icons/Visitation'
import Adoption from '../../icons/Adoption'

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${rem(20)};
  border: ${rem(1)} solid rgba(0, 56, 81, 0.3);
  border-radius: ${rem(8)};
  padding-inline-end: ${rem(24)};
  overflow: hidden;
`

const StyledSpan = styled.span`
  font-size: ${rem(20)};
  font-weight: 900;
  color: ${({ theme }) => theme.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  :first-letter {
    text-transform: uppercase;
  }
`

const StyledIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${rem(72)};
  overflow: hidden;
`

const LifeSituationLink = ({ icon, link }: LifeSituationLinkViewModel) => {
  const renderIcon = {
    Statistics: <Statistics />,
    DivorceSeparation: <DivorceSeparation />,
    Guardianship: <Guardianship />,
    AnnualReports: <AnnualReports />,
    AddressesAndOpeningHours: <AddressesAndOpeningHours />,
    Recognitions: <Recognitions />,
    Restraint: <Restraint />,
    ChildResidence: <ChildResidence />,
    PaymentOfFee: <PaymentOfFee />,
    Contribution: <Contribution />,
    ChildSupport: <ChildSupport />,
    PaternityCoMotherhood: <PaternityCoMotherhood />,
    MovingWithoutConsent: <MovingWithoutConsent />,
    ParentalResponsibility: <ParentalResponsibility />,
    FuturePowerOfAttorney: <FuturePowerOfAttorney />,
    InternationalMarriages: <InternationalMarriages />,
    JobsAtTheFamilyCourt: <JobsAtTheFamilyCourt />,
    Complaint: <Complaint />,
    MeetingsAtTheFamilyCourt: <MeetingsAtTheFamilyCourt />,
    Names: <Names />,
    AboutTheFamilyCourt: <AboutTheFamilyCourt />,
    Organization: <Organization />,
    PerformancePlans: <PerformancePlans />,
    HowToContactUs: <HowToContactUs />,
    CaseProcessingTimes: <CaseProcessingTimes />,
    Visitation: <Visitation />,
    Adoption: <Adoption />,
  }
  return (
    <StyledLink href={link?.href} target={link?.target}>
      <StyledIconWrapper>{icon && renderIcon[icon]}</StyledIconWrapper>

      <StyledSpan>{link?.label}</StyledSpan>
    </StyledLink>
  )
}

export default LifeSituationLink
