// @flow

import * as React from 'react'

const Contribution = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.contribution-cls-2,.contribution-cls-5{stroke-width:3px;stroke-linecap:round;stroke-linejoin:round;fill:none}.contribution-cls-6{fill:#e2ece6}.contribution-cls-2{stroke:#d4cbe0}.contribution-cls-5{stroke:#e2ece6}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          d="M0 0h197.7v214.2H0z"
          style={{
            fill: '#0e642e',
          }}
        />
        <path
          d="m87.1 70.8-31.6 48.7c-4.5 6.9 1.1 15.5 10 15.5h63.8c8.9 0 14.5-8.6 10-15.5l-31.6-48.7"
          style={{
            fill: '#3e8358',
          }}
        />
        <path
          d="m137.6 116.7-29.8-45.9M87.1 70.8l-31.6 48.7s-3.3 5.7 0 10.3"
          className="contribution-cls-5"
        />
        <path
          d="m107.7 56.9 7.1-10.9H80.1l7.1 10.9"
          style={{
            fill: '#3e8358',
            stroke: '#cfe0d5',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3,
          }}
        />
        <path
          d="M107.8 63.8H87.1"
          style={{
            stroke: '#cfe0d5',
            fill: 'none',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3,
          }}
        />
        <circle
          cx={97.5}
          cy={105.7}
          r={12.2}
          style={{
            fill: '#0e642e',
            stroke: '#e2ece6',
            strokeMiterlimit: 10,
            strokeWidth: 3,
          }}
        />
        <path
          d="M91.2 102.6c0-.4.2-.7.7-.7h.8c.4 0 .7.2.7.7v2.1h.6l1.1-2.3c.2-.3.4-.5.8-.5h.8c.3 0 .4 0 .5.2v.6l-1.5 2.7 1.6 3c.1.2.2.4 0 .6 0 .2-.3.2-.5.2h-1c-.4 0-.6-.2-.8-.5l-1.1-2.3h-.6v2.1c0 .4-.2.7-.7.7h-.8c-.5 0-.7-.2-.7-.7v-6.1l.1.2ZM98.2 102.6c0-.4.2-.7.7-.7h2.9c.2 0 .4 0 .6.1.2 0 .4.2.6.3.2.1.3.3.5.5l.3.6c0 .2.1.5.1.8v.6c0 .2-.1.4-.2.6 0 .2-.2.4-.3.5-.1.2-.3.3-.5.4s0 .1.1.2l.1.1s0 .1.1.2l.9 1.6c.1.3.2.5 0 .6-.1.1-.3.2-.5.2h-1c-.2 0-.3 0-.4-.1-.1 0-.3-.2-.3-.4l-.9-1.8h-.5v1.6c0 .4-.2.7-.7.7H99c-.5 0-.7-.2-.7-.7v-6.1l-.1.2Zm2.6 2.6c.3 0 .5 0 .6-.2.1-.1.2-.3.2-.6v-.3c0-.1-.1-.2-.3-.2h-.9v1.4h.5l-.1-.1Z"
          className="contribution-cls-6"
        />
        <path
          d="m48 180.4 12.4-13.2c2.6-2.8 6.2-4.3 10-4.3h50.8c1.6 0 3.2-.6 4.4-1.7l43.4-40.8c1.3-1.2 1.2-3.4-.2-4.5-5.8-4.6-12.8-6.2-21.4.3-6.3 4.8-14.7 11-19.5 14.7l-5.4 3.9v.2c-2.8-5.4-8.1-6-10.2-6H94.6c-8.2-5.5-13.4-6.7-23-6.7-11.5 0-20.8 5.6-32.5 20.2l-14.3 13.4"
          style={{
            fill: '#5e4876',
          }}
        />
        <path
          d="M127.9 131.2c4.8-3.6 13.2-9.9 19.5-14.7 8.7-6.5 15.7-5 21.4-.3 1.4 1.1 1.5 3.2.2 4.5l-43.4 40.8c-1.2 1.1-2.8 1.7-4.4 1.7H70.4c-3.8 0-7.4 1.6-10 4.3L48 180.7"
          className="contribution-cls-2"
        />
        <path
          d="M87.4 144.3h35.3c1.1 0 1.9-.9 1.9-2-.9-11.9-9.4-13.1-12.1-13.1H94.8c-8.2-5.5-13.4-6.7-23-6.7-11.5 0-20.8 5.6-32.5 20.2L25 156.1"
          className="contribution-cls-2"
        />
      </g>
    </g>
  </svg>
)

export default Contribution
