//@flow
import * as React from 'react'

const Recognitions = ({ style, ...rest }: Object) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
      <defs>
        <style>
          {
            '.recognitions-cls-1{stroke:#0e642e}.recognitions-cls-1,.recognitions-cls-2,.recognitions-cls-4{fill:none;stroke-linecap:round}.recognitions-cls-1,.recognitions-cls-4{stroke-width:3px;stroke-miterlimit:10}.recognitions-cls-2{stroke:#d4cbe0;stroke-linejoin:round;stroke-width:3.1px}.recognitions-cls-4{stroke:#e2ece6}'
          }
        </style>
      </defs>
      <g id="Layer_1">
        <g id="Layer_1-2" data-name="Layer_1">
          <path
            d="M.26.23h197.48v213.94H.26z"
            style={{
              fill: '#0e642e',
            }}
          />
          <path
            d="M54.22 45.67h92.36v121.5H54.22z"
            style={{
              fill: '#5e4876',
            }}
          />
          <path
            d="M146.58 120.38V45.67H54.23v121.5h60.01"
            style={{
              strokeLinejoin: 'round',
              stroke: '#d4cbe0',
              strokeWidth: 3,
              strokeLinecap: 'round',
              fill: 'none',
            }}
          />
          <circle
            cx={100.01}
            cy={71.85}
            r={12.21}
            style={{
              stroke: '#f2f0f4',
              fill: '#5e4876',
              strokeWidth: '3.1px',
              strokeLinejoin: 'round',
              strokeLinecap: 'round',
            }}
          />
          <path
            d="M129.48 97.76H69.84M129.74 110.81h-59.9M112.22 126.64H69.84"
            className="recognitions-cls-2"
          />
          <path
            d="M174.27 154.33c0 15.29-12.39 27.68-27.68 27.68s-27.68-12.39-27.68-27.68 12.39-27.68 27.68-27.68c7.19 0 13.74 2.74 18.66 7.23a27.632 27.632 0 0 1 9.03 20.46Z"
            style={{
              fill: '#3e8358',
            }}
          />
          <path
            d="m131.2 150.77 12.1 12.11 29.46-29.46"
            className="recognitions-cls-4"
          />
          <path
            d="M171.34 141.92c1.87 3.73 2.93 7.95 2.93 12.41 0 15.29-12.39 27.68-27.68 27.68s-27.68-12.39-27.68-27.68 12.39-27.68 27.68-27.68c7.19 0 13.74 2.74 18.66 7.23"
            className="recognitions-cls-4"
          />
        </g>
      </g>
    </svg>
  )
}

export default Recognitions
