// @flow
import * as React from 'react'

const Organization = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.organization-cls-1{fill:none;stroke:#ced7dc}.organization-cls-1,.organization-cls-2{stroke-linecap:round}.organization-cls-1,.organization-cls-2,.organization-cls-3{stroke-linejoin:round;stroke-width:3.1px}.organization-cls-2{fill:#547385;stroke:#e2e7ea}.organization-cls-3{fill:#0b3650;stroke:#f8b640}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          d="M0 0h197.7v214.2H0z"
          style={{
            fill: '#0b3650',
          }}
        />
        <path
          d="M44.1 117v-16.6h110V117M99.1 117V92.7"
          className="organization-cls-1"
        />
        <circle cx={99.3} cy={55.9} r={7.9} className="organization-cls-3" />
        <path
          d="M116.2 84.5c0-7.9-4.8-14.6-11.6-17.1-1.4 1.4-3.4 2.3-5.6 2.3-2.2 0-4.2-.9-5.6-2.3-6.7 2.5-11.6 9.2-11.6 17.1"
          className="organization-cls-3"
        />
        <circle cx={154.3} cy={133.2} r={7.9} className="organization-cls-2" />
        <path
          d="M171.2 161.8c0-7.9-4.8-14.6-11.6-17.1-1.4 1.4-3.4 2.3-5.6 2.3h0c-2.2 0-4.2-.9-5.6-2.3-6.7 2.5-11.6 9.2-11.6 17.1"
          className="organization-cls-2"
        />
        <circle cx={99.3} cy={133.2} r={7.9} className="organization-cls-2" />
        <path
          d="M116.2 161.8c0-7.9-4.8-14.6-11.6-17.1-1.4 1.4-3.4 2.3-5.6 2.3h0c-2.2 0-4.2-.9-5.6-2.3-6.7 2.5-11.6 9.2-11.6 17.1"
          className="organization-cls-2"
        />
        <circle cx={44.3} cy={133.2} r={7.9} className="organization-cls-2" />
        <path
          d="M61.3 161.8c0-7.9-4.8-14.6-11.6-17.1-1.4 1.4-3.4 2.3-5.6 2.3h0c-2.2 0-4.2-.9-5.6-2.3-6.7 2.5-11.6 9.2-11.6 17.1"
          className="organization-cls-2"
        />
      </g>
    </g>
  </svg>
)

export default Organization
