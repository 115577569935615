// @flow
import * as React from 'react'

const Statistics = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.statistics-cls-1{fill:#f59d00}.statistics-cls-1,.statistics-cls-2,.statistics-cls-3{stroke:#c8c9d3;stroke-linecap:round;stroke-linejoin:round;stroke-width:3.1px}.statistics-cls-2{fill:none}.statistics-cls-3{fill:#3a5069}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          d="M-.2 0h198.4v215H-.2z"
          style={{
            fill: '#0b3650',
          }}
        />
        <path d="M27.5 164.8h143.2" className="statistics-cls-2" />
        <path
          d="M39.1 164.1V113h16.5v51.1M73.7 95.2h16.5V164H73.7zM106.1 113h16.5v51.1h-16.5zM142.7 95.2h16.5V164h-16.5z"
          className="statistics-cls-1"
        />
        <path
          d="m47.3 85.5 34.6-24.7 32.4 24.7L151 56.9"
          className="statistics-cls-2"
        />
        <circle cx={47.3} cy={83.5} r={9.5} className="statistics-cls-3" />
        <circle cx={81.9} cy={60.8} r={9.5} className="statistics-cls-3" />
        <circle cx={114.3} cy={83.5} r={9.5} className="statistics-cls-3" />
        <circle cx={151} cy={56.9} r={9.5} className="statistics-cls-3" />
      </g>
    </g>
  </svg>
)

export default Statistics
