// @flow
// Import additional required dependencies
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { useState, useRef, useEffect } from 'react'
import Ribbon from '../../components/Ribbon/Ribbon'
import LifeSituationLink from '../../components/LifeSituationLink/LifeSituationLink'
import Heading from '../../components/Heading/Heading'
import { mediaQuery } from '../../styles/media'
import type { LifeSituationsViewModel } from '../../types/LifeSituationsViewModel'

const StyledRibbon = styled(Ribbon)`
  background-color: #fff;
  padding-top: ${rem(28)};
  padding-bottom: ${rem(56)};
  display: none;
  ${mediaQuery.sm`
    display:block;
    padding-top: ${rem(40)};
  `}
`

const StyledCarouselRibbon = styled(StyledRibbon)`
  display: block;
  & > div:first-child {
    padding-inline: 0;
  }
  ${mediaQuery.sm`
    display: none;
  `}
`

const StyledSubTitle = styled.p`
  font-size: ${rem(12)};
  font-family: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary};
  padding-inline: ${rem(30)};
  ${mediaQuery.sm`
    padding-inline: 0;
  `}
`

const StyledHeading = styled(Heading)`
  padding-inline: ${rem(30)};
  ${mediaQuery.sm`
    padding-inline: 0;
    font-size: ${rem(44)};
  `}
`

const SyledListItem = styled.li`
  overflow: hidden;
`

const StyledCarouselItem = styled.div`
  min-width: 85vw;
  overflow: hidden;
`

const StyledList = styled.ul`
  margin-top: ${rem(20)};
  list-style: none;
  padding-inline: 0;
  display: none;
  flex-direction: column;
  gap: ${rem(16)};
  ${mediaQuery.sm`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${rem(20)};
  `}
  ${mediaQuery.md`
    grid-template-columns: repeat(3, 1fr);
  `}
`

const StyledCarousel = styled.div`
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    /* WebKit */
    display: none;
  }
  display: flex;
  overflow-x: auto;
  gap: ${rem(16)};
  padding: ${rem(16)} 0;
  scroll-snap-type: x mandatory;
  scroll-snap-align: center;
  & > div:first-of-type {
    padding-left: ${rem(30)};
  }
  & > div:last-of-type {
    padding-right: ${rem(30)};
  }
`

const StyledCarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  list-style: none;
  scroll-snap-align: center;
`

const PaginationDots = styled.div`
  display: flex;
  justify-content: center;
  gap: ${rem(8)};
  margin-top: ${rem(16)};
`

const Dot = styled.div`
  width: ${rem(10)};
  height: ${rem(10)};
  border-radius: 50%;
  background-color: ${({ active, theme }) =>
    active ? theme.primary : theme.bgColor};
`

// Helper function
function chunkArray(arr, chunkSize) {
  const result = []
  if (!arr) return null
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize))
  }
  return result
}

function LifeSituations({
  title,
  subTitle,
  lifeSituations,
}: LifeSituationsViewModel) {
  const ChunkedLifeSituations =
    lifeSituations && lifeSituations.length && chunkArray(lifeSituations, 5)
  const [currentPage, setCurrentPage] = useState(0)
  const carouselRef = useRef(null)
  const carouselLinkRef = useRef(null)

  // Handle carousel scroll for pagination dots
  useEffect(() => {
    const carousel = carouselRef.current
    const carouselLink = carouselLinkRef.current
    const handleScroll = () => {
      const scrollPosition = carousel && carousel.scrollLeft
      const itemWidth = carouselLink && carouselLink.offsetWidth
      const pageIndex =
        scrollPosition && itemWidth && Math.round(scrollPosition / itemWidth)
      setCurrentPage(pageIndex)
    }

    if (carousel) {
      carousel.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (carousel) {
        carousel.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <React.Fragment>
      <StyledCarouselRibbon>
        {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
        {title && (
          <StyledHeading tag="h2" level={2} responsiveSize>
            {title}
          </StyledHeading>
        )}
        {ChunkedLifeSituations && ChunkedLifeSituations.length > 0 && (
          <>
            <StyledCarousel ref={carouselRef}>
              {ChunkedLifeSituations.map((lifeSituations, index) => (
                <StyledCarouselContainer key={`carousel-page-${index}`}>
                  {lifeSituations.map((lifeSituation, index) => (
                    <StyledCarouselItem
                      ref={carouselLinkRef}
                      key={`life-situation-${index}`}
                    >
                      <LifeSituationLink
                        link={lifeSituation?.link}
                        icon={lifeSituation?.icon}
                      />
                    </StyledCarouselItem>
                  ))}
                </StyledCarouselContainer>
              ))}
            </StyledCarousel>
            {/* Pagination Dots */}
            <PaginationDots>
              {ChunkedLifeSituations.map((_, index) => (
                <Dot key={index} active={currentPage === index} />
              ))}
            </PaginationDots>
          </>
        )}
      </StyledCarouselRibbon>

      <StyledRibbon>
        {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
        {title && (
          <StyledHeading tag="h2" level={2} responsiveSize>
            {title}
          </StyledHeading>
        )}
        {lifeSituations && lifeSituations.length > 0 && (
          <StyledList>
            {lifeSituations.map((lifeSituation, index) => (
              <SyledListItem key={`life-situation-desktop-${index}`}>
                <LifeSituationLink
                  link={lifeSituation?.link}
                  icon={lifeSituation?.icon}
                />
              </SyledListItem>
            ))}
          </StyledList>
        )}
      </StyledRibbon>
    </React.Fragment>
  )
}

export default LifeSituations
