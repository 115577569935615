// @flow
import * as React from 'react'

const InternationalMarriages = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.international-marriages-cls-3,.international-marriages-cls-4{stroke:#d4cbe0;stroke-width:2.6px;stroke-linecap:round;stroke-linejoin:round;fill:none}.international-marriages-cls-4{stroke:#cfe0d5;stroke-width:3.1px}.international-marriages-cls-6{fill:#0e642e}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <path d="M0 0h198v214.4H0z" className="international-marriages-cls-6" />
      <path
        d="M153.983 107.742c0 31.18-25.276 56.457-56.454 56.457s-56.451-25.277-56.451-56.457 25.273-56.457 56.451-56.457 56.454 25.277 56.454 56.457Z"
        style={{
          fill: '#3e8358',
        }}
      />
      <path
        d="M112.55 162.177a56.512 56.512 0 0 1-15.022 2.021c-31.178 0-56.451-25.277-56.451-56.457S66.35 51.284 97.528 51.284s56.454 25.277 56.454 56.457"
        className="international-marriages-cls-4"
      />
      <path
        d="M118.556 107.742c0 31.18-9.413 56.457-21.029 56.457s-21.031-25.277-21.031-56.457 9.418-56.457 21.031-56.457 21.029 25.277 21.029 56.457ZM44.461 126.56h85.22M44.461 88.922h106.135"
        className="international-marriages-cls-4"
      />
      <path
        d="m145.409 128.668-10.559-9.838 3.681-5.798h13.755l3.681 5.798-10.558 9.838z"
        style={{
          fill: '#5e4876',
        }}
      />
      <path
        d="M138.376 129.646c-11.145 3.081-19.338 13.268-19.338 25.393 0 14.564 11.807 26.371 26.371 26.371s26.371-11.807 26.371-26.371c0-12.208-8.307-22.449-19.569-25.453"
        style={{
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          stroke: '#d4cbe0',
          strokeWidth: '2.6px',
          fill: '#5e4876',
        }}
      />
      <path
        d="M134.85 118.83h21.117M145.409 118.11v10.558M152.286 113.032l-6.877 5.798M139.072 113.032l6.878 5.798M145.409 128.668l-10.559-9.838 3.681-5.798h13.755l3.681 5.798-10.558 9.838z"
        className="international-marriages-cls-3"
      />
      <circle
        cx={145.409}
        cy={154.982}
        r={19.503}
        className="international-marriages-cls-6"
      />
      <path
        d="M152.211 136.84c7.38 2.76 12.645 9.856 12.645 18.199 0 10.74-8.707 19.447-19.447 19.447s-19.447-8.707-19.447-19.447c0-8.257 5.155-15.294 12.414-18.115"
        className="international-marriages-cls-3"
      />
    </g>
  </svg>
)

export default InternationalMarriages
