// @flow
import * as React from 'react'

const Adoption = ({ style, ...rest }: Object) => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 198 214.401">
  <defs>
    <style>{
      '.cls-1,.cls-2{fill:#5e4876;}.cls-3{stroke-width:3px;}.cls-3,.cls-2{stroke:#dfdae4;}.cls-3,.cls-2,.cls-4,.cls-5{stroke-linecap:round;stroke-linejoin:round;}.cls-3,.cls-4{fill:none;}.cls-2,.cls-4,.cls-5{stroke-width:3.1px;}.cls-6,.cls-5{fill:#3e8358;}.cls-4,.cls-5{stroke:#cfe0d5;}.cls-7{fill:#0e642e;}'
      }
    </style>
  </defs>
  <g>
    <g id="Layer_1">
      <rect class="cls-7" width="198" height="214.401"/>
      <path class="cls-6" d="M138.989,109.2c0-17.156-10.53-31.708-25.193-37.091-3.105,3.146-7.411,5.104-12.181,5.104h.203c-4.77,0-9.076-1.957-12.181-5.104-14.663,5.383-25.193,19.935-25.193,37.091"/>
      <g>
        <path class="cls-4" d="M139.104,108.405c0-17.097-10.494-31.598-25.106-36.962-3.094,3.135-7.385,5.086-12.138,5.086-4.833,0-9.175-2.029-12.28-5.257"/>
        <path class="cls-4" d="M89.063,71.443c-14.612,5.365-25.106,19.866-25.106,36.962"/>
      </g>
      <circle class="cls-5" cx="101.566" cy="47.13" r="17.064"/>
      <path class="cls-4" d="M101.629,30.066c-3.015,0-5.459,2.444-5.459,5.459,0,2.412,1.955,4.368,4.368,4.368,1.93,0,3.494-1.564,3.494-3.494"/>
      <path class="cls-4" d="M96.93,52.352c1.1,1.408,2.809,2.316,4.735,2.316s3.635-.909,4.735-2.316"/>
      <path class="cls-2" d="M101.531,93.2c8.81-11.662,23.158-3.659,22.234,8.664-.839,11.196-22.234,22.858-22.234,22.858,0,0-21.395-11.662-22.234-22.858-.924-12.324,13.424-20.327,22.234-8.664Z"/>
      <path class="cls-1" d="M49.362,181.512l12.442-13.243c2.586-2.753,6.196-4.314,9.973-4.314h50.84c1.629,0,3.197-.622,4.384-1.739l43.353-40.821c1.313-1.236,1.239-3.355-.169-4.48-5.782-4.622-12.772-6.172-21.446.339-6.337,4.756-14.662,11.039-19.49,14.685l-5.375,3.854.089.154c-2.903-5.354-8.236-5.993-10.257-5.993h-17.667c-8.234-5.529-13.374-6.667-23.027-6.668-11.537,0-20.801,5.588-32.483,20.243l-14.275,13.441"/>
      <path class="cls-3" d="M129.249,132.37c4.828-3.646,13.154-9.929,19.49-14.685,8.674-6.511,15.664-4.96,21.446-.339,1.408,1.126,1.481,3.245.169,4.48l-43.353,40.821c-1.187,1.117-2.755,1.739-4.384,1.739h-50.84c-3.777,0-7.387,1.561-9.973,4.314l-12.442,13.243"/>
      <path class="cls-3" d="M88.722,145.448h35.25c1.082,0,1.949-.916,1.871-1.995-.859-11.932-9.443-13.069-12.137-13.069h-17.667c-8.234-5.529-13.374-6.667-23.027-6.668-11.537,0-20.801,5.588-32.483,20.243l-14.275,13.441"/>
    </g>
  </g>
</svg>
)

export default Adoption
