// @flow
import * as React from 'react'

const ParentalResponsibility = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.parental-responsibility-cls-1,.parental-responsibility-cls-2{fill:none}.parental-responsibility-cls-1,.parental-responsibility-cls-2,.parental-responsibility-cls-3,.parental-responsibility-cls-4{stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}.parental-responsibility-cls-1,.parental-responsibility-cls-3{stroke:#cceef5}.parental-responsibility-cls-3,.parental-responsibility-cls-5{fill:#4cc2de}.parental-responsibility-cls-2,.parental-responsibility-cls-4{stroke:#faccd8}.parental-responsibility-cls-4{fill:#ea3361}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          d="M0 0h197.7v214.2H0z"
          style={{
            fill: '#00a9cf',
          }}
        />
        <path
          d="M160.8 127h19.8v23.4h-19.8z"
          className="parental-responsibility-cls-5"
        />
        <path
          d="M170.7 92.6c-2.3 0-4.4-1-5.9-2.5-7.1 2.6-12.2 9.7-12.2 18v14.2s.5 5.7 5.7 5.7h23.6c5.2 0 5.7-5.7 5.7-5.7v-14.2c0-8.3-3.3-16.2-10.4-18.8-1.5 1.5-5.4 3.3-7.7 3.3M18.8 127.1h19.8v23.4H18.8z"
          className="parental-responsibility-cls-5"
        />
        <path
          d="M28.6 92.6c2.3 0 5-2.6 6.6-4.2 7.1 2.6 11.6 11.4 11.6 19.7v14.2s-.5 5.7-5.7 5.7H17.6c-5.2 0-5.7-5.7-5.7-5.7v-14.2c0-8.3 3.3-16.2 10.4-18.8 1.5 1.5 5.4 3.3 7.7 3.3"
          className="parental-responsibility-cls-5"
        />
        <path d="M97.6 91.7v47" className="parental-responsibility-cls-1" />
        <circle
          cx={98.6}
          cy={85.2}
          r={6.6}
          className="parental-responsibility-cls-3"
        />
        <path
          d="M113.3 108.4c0 7.8 6.3 14.2 14.2 14.2s14.2-6.3 14.2-14.2h-28.4Z"
          className="parental-responsibility-cls-4"
        />
        <path
          d="M141.1 106.4 127.5 88l-13.6 18.4"
          className="parental-responsibility-cls-2"
        />
        <path
          d="M56.3 92.5c0 7.8 6.3 14.2 14.2 14.2s14.2-6.3 14.2-14.2H56.3Z"
          className="parental-responsibility-cls-4"
        />
        <path
          d="M84.1 90.5 70.5 72.1 56.9 90.5"
          className="parental-responsibility-cls-2"
        />
        <path
          d="M111.1 146.1h-27c-2 0-3.7-1.7-3.7-3.7h0c0-2 1.7-3.7 4.7-3.7h27c1 0 2.7 1.7 2.7 3.7h0c0 2-1.7 3.7-3.7 3.7Z"
          className="parental-responsibility-cls-3"
        />
        <circle
          cx={170.1}
          cy={76.6}
          r={8.4}
          className="parental-responsibility-cls-3"
        />
        <path
          d="M157.5 126.8c-5.2 0-5.8-5.8-5.8-5.8v-14.3c0-8.4 5.3-15.6 12.5-18.3 1.5 1.6 3.7 2.6 6 2.6s4.5-1 6-2.5c2.2.8 4.3 2.1 6 3.6M161.7 126.2v23.7"
          className="parental-responsibility-cls-1"
        />
        <circle
          cx={29.2}
          cy={76.6}
          r={8.4}
          className="parental-responsibility-cls-3"
        />
        <path
          d="M41.9 126.8c5.2 0 5.8-5.8 5.8-5.8v-14.3c0-8.4-5.3-15.6-12.5-18.3-1.5 1.6-3.7 2.6-6 2.6s-4.5-1-6-2.5c-2.2.8-4.3 2.1-6 3.6M37.6 126.2v23.7"
          className="parental-responsibility-cls-1"
        />
      </g>
    </g>
  </svg>
)

export default ParentalResponsibility
