// @flow
import * as React from 'react'

const ChildResidence = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.child-residence-cls-2,.child-residence-cls-3,.child-residence-cls-4,.child-residence-cls-5,.child-residence-cls-7{stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}.child-residence-cls-4,.child-residence-cls-5{stroke:#f8bfce}.child-residence-cls-7{fill:#4cc2de}.child-residence-cls-2,.child-residence-cls-3,.child-residence-cls-7{stroke:#cceef5}.child-residence-cls-2,.child-residence-cls-5{fill:none}.child-residence-cls-3{fill:#d4cbe0}.child-residence-cls-10,.child-residence-cls-4{fill:#00a9cf}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <path d="M0 0h197.74v214.17H0z" className="child-residence-cls-10" />
      <path
        d="M160.17 159.95H91.08l-1.23-51 33.95-28.09 36.37 30.27v48.82z"
        className="child-residence-cls-10"
      />
      <path
        d="M87.19 158.66c6 0 6.63-6.63 6.63-6.63v-16.45c0-9.67-5.94-17.88-14.2-20.91a9.64 9.64 0 0 1-6.87 2.88h.11c-2.69 0-5.12-1.1-6.87-2.88-8.27 3.04-14.2 11.24-14.2 20.91v16.45s.63 6.63 6.63 6.63"
        style={{
          stroke: '#00a9cf',
          fill: '#00a9cf',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 3,
        }}
      />
      <path d="M160.17 111.13v52.01" className="child-residence-cls-5" />
      <path
        d="M149.73 85.83V71.97h9.76v21.75"
        className="child-residence-cls-4"
      />
      <circle
        cx={73}
        cy={104.69}
        r={14.43}
        className="child-residence-cls-10"
      />
      <path
        d="M121.44 125.7c0-3.5-2.83-6.33-6.33-6.33s-6.33 2.83-6.33 6.33c0 7.41 7.78 10.07 12.66 12.36 5.06-2.35 12.66-4.94 12.66-12.36 0-3.5-2.83-6.33-6.33-6.33s-6.33 2.83-6.33 6.33Z"
        style={{
          fill: '#ea3361',
          stroke: '#f8bfce',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 3,
        }}
      />
      <path
        d="M91.08 159.95h76.98v10.16H91.19"
        className="child-residence-cls-4"
      />
      <path
        d="M83.82 154.16v18.08H61.8v-22.42h22.52l-.5 4.34z"
        style={{
          fill: '#4cc2de',
        }}
      />
      <circle cx={73} cy={104.69} r={8.18} className="child-residence-cls-7" />
      <path
        d="M84.99 155.29c5.08 0 5.62-5.62 5.62-5.62v-13.93c0-8.19-5.03-15.14-12.03-17.71a8.158 8.158 0 0 1-5.82 2.44h.1c-2.28 0-4.33-.93-5.82-2.44-7 2.57-12.03 9.52-12.03 17.71v13.93s.53 5.62 5.62 5.62"
        className="child-residence-cls-7"
      />
      <path
        d="M73.53 158.56v12.66M83.82 151.45v19.77"
        className="child-residence-cls-3"
      />
      <circle
        cx={57.4}
        cy={64.43}
        r={12.22}
        className="child-residence-cls-2"
      />
      <path
        d="M38.97 137.54c-7.6 0-8.4-8.4-8.4-8.4v-20.82c0-12.25 7.78-22.75 18.25-26.6 2.22 2.31 5.33 3.77 8.8 3.77 3.4 0 6.48-1.4 8.69-3.64 3.24 1.19 6.19 3 8.73 5.3"
        className="child-residence-cls-2"
      />
      <path d="M45.18 136.67v34.55" className="child-residence-cls-3" />
      <path
        d="m87.91 108.95 34.79-28.09 42.98 34.71 7.35-9.11-50.35-40.66"
        className="child-residence-cls-5"
      />
      <path
        d="m85.21 96.08 37.5-30.28"
        style={{
          stroke: '#f8bfce',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 3,
          fill: '#d7e3e5',
        }}
      />
    </g>
  </svg>
)

export default ChildResidence
