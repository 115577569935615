// @flow
import * as React from 'react'

const Complaint = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.complaint-cls-2{stroke-linejoin:round;stroke-linecap:round;stroke-width:3.1px;fill:none;stroke:#d4cbe0}.complaint-cls-8{fill:#cfe0d5}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <path
        d="M.016-.101H198V214.4H.016z"
        style={{
          fill: '#0e642e',
        }}
      />
      <path
        d="M36.948 32.111h104.76v137.813H36.948z"
        style={{
          fill: '#5e4876',
        }}
        transform="rotate(-180 89.328 101.017)"
      />
      <path
        d="M141.708 116.853V32.111H36.948v137.813h68.078M123.439 91.642h-67.65M123.735 107.232H55.789M123.439 60.463h-67.65M123.735 76.053H55.789M103.866 122.822H55.789"
        className="complaint-cls-2"
      />
      <path
        d="M173.11 155.358c0 17.343-14.059 31.402-31.402 31.402s-31.402-14.059-31.402-31.402 14.059-31.402 31.402-31.402a31.29 31.29 0 0 1 21.162 8.201 31.51 31.51 0 0 1 6.917 9.126 31.278 31.278 0 0 1 3.323 14.075Z"
        style={{
          fill: '#3e8358',
        }}
      />
      <path
        d="M173.11 155.358c0 17.343-14.059 31.402-31.402 31.402s-31.402-14.059-31.402-31.402 14.059-31.402 31.402-31.402a31.29 31.29 0 0 1 21.162 8.201 31.51 31.51 0 0 1 6.917 9.126 31.278 31.278 0 0 1 3.323 14.075Z"
        style={{
          strokeMiterlimit: 10,
          stroke: '#cfe0d5',
          fill: 'none',
          strokeWidth: '3.1px',
        }}
      />
      <circle cx={132.126} cy={148.237} r={2.756} className="complaint-cls-8" />
      <circle cx={151.166} cy={148.237} r={2.756} className="complaint-cls-8" />
      <path
        d="M156.167 168.586c-2.256-3.515-5.544-6.239-9.552-7.523-7.427-2.379-15.101.887-19.365 7.523"
        style={{
          strokeMiterlimit: 10,
          stroke: '#cfe0d5',
          fill: 'none',
          strokeWidth: '3.1px',
          strokeLinecap: 'round',
        }}
      />
    </g>
  </svg>
)

export default Complaint
