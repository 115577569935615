// @flow
import * as React from 'react'

const MovingWithoutConsent = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.movingWithoutConsent-cls-1,.movingWithoutConsent-cls-2,.movingWithoutConsent-cls-4,.movingWithoutConsent-cls-6{stroke-linecap:round;stroke-linejoin:round}.movingWithoutConsent-cls-1,.movingWithoutConsent-cls-7{fill:#5e4876}.movingWithoutConsent-cls-1{stroke:#dfdae4}.movingWithoutConsent-cls-1,.movingWithoutConsent-cls-6{stroke-width:2.8px}.movingWithoutConsent-cls-2,.movingWithoutConsent-cls-4{stroke-width:3.1px;fill:none}.movingWithoutConsent-cls-2,.movingWithoutConsent-cls-6{stroke:#cfe0d5}.movingWithoutConsent-cls-4{stroke:#d4cbe0}.movingWithoutConsent-cls-6,.movingWithoutConsent-cls-9{fill:#3e8358}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          d="M0 0h197.7v214.2H0z"
          style={{
            fill: '#0e642e',
          }}
        />
        <path
          d="M31.3 148.7h31.2v27.9H31.3z"
          className="movingWithoutConsent-cls-7"
        />
        <path
          d="M63.1 153.9c7 0 7.7-7.7 7.7-7.7l10.6-36.5c0-11.2-17.5-3.5-27.1-7-2 2.1-4.9 3.3-8 3.3h.1c-3.1 0-5.9-1.3-8-3.3-9.6 3.5-16.5 13.1-16.5 24.3v19.1s.7 7.7 7.7 7.7"
          className="movingWithoutConsent-cls-7"
        />
        <path
          d="M77.1 148.7h30.2v27.9H77.1z"
          className="movingWithoutConsent-cls-9"
        />
        <path
          d="M109.8 153.9c7 0 7.7-7.7 7.7-7.7v-19.1c0-11.2-6.9-20.8-16.5-24.3-2 2.1-4.9 3.3-8 3.3h.1c-3.1 0-5.9-1.3-8-3.3-9.6 3.5-16.5 13.1-16.5 24.3v19.1s.7 7.7 7.7 7.7"
          className="movingWithoutConsent-cls-9"
        />
        <circle
          cx={46.4}
          cy={85.1}
          r={11.5}
          style={{
            strokeWidth: '3.1px',
            stroke: '#dfdae4',
            fill: '#5e4876',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          d="M63.5 148.7v27.9M28.9 154.1c-7.2 0-7.9-7.9-7.9-7.9v-19.7c0-11.6 7.1-21.4 17-25 2.1 2.1 5 3.4 8.2 3.4s6.2-1.4 8.3-3.6M30.6 148.7v27.9M47.1 158.8v17.8M71 103c-5.9-.1-12.1 0-16.1-1.4"
          className="movingWithoutConsent-cls-4"
        />
        <circle
          cx={93}
          cy={85.1}
          r={11.5}
          style={{
            fill: '#3e8358',
            stroke: '#cfe0d5',
            strokeWidth: '3.1px',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          d="M75.9 148.7v27.9M110.4 154.1c7.2 0 7.9-7.9 7.9-7.9v-19.7c0-11.6-7.1-21.4-17-25-2.1 2.1-5 3.4-8.2 3.4s-6.2-1.4-8.3-3.6M108.8 148.7v27.9M92.2 158.8v17.8M84.5 101.6c-9.9 3.6-17 13.4-17 25v19.7s.9 7.7 8 7.7"
          className="movingWithoutConsent-cls-2"
        />
        <path
          d="M131.7 143.6h41v34.5h-41z"
          className="movingWithoutConsent-cls-1"
        />
        <path
          d="M146 143.6h12.4v8.7H146z"
          className="movingWithoutConsent-cls-1"
        />
        <path
          d="M137.6 107.1h41v34.5h-41z"
          className="movingWithoutConsent-cls-6"
        />
        <path
          d="M151.9 107.1h12.4v8.7h-12.4z"
          className="movingWithoutConsent-cls-6"
        />
        <path
          d="M131.7 70.6h41v34.5h-41z"
          className="movingWithoutConsent-cls-1"
        />
        <path
          d="M146 70.6h12.4v8.7H146z"
          className="movingWithoutConsent-cls-1"
        />
        <path
          d="M115.2 113.8c-2.6 2.6-6.8 2.6-9.3 0-2.6-2.6-2.6-6.8 0-9.3s5.1-.9 7.7 1.7 4.2 5.1 1.7 7.7l-.1-.1Z"
          style={{
            strokeMiterlimit: 10,
            strokeWidth: '2.4px',
            stroke: '#dfdae4',
            fill: '#5e4876',
          }}
        />
      </g>
    </g>
  </svg>
)

export default MovingWithoutConsent
