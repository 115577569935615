// @flow
import * as React from 'react'

const JobsAtTheFamilyCourt = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.jobsAtTheFamilyCourt-cls-3,.jobsAtTheFamilyCourt-cls-4,.jobsAtTheFamilyCourt-cls-5{stroke-linecap:round;stroke-linejoin:round;stroke-width:3.1px}.jobsAtTheFamilyCourt-cls-5{stroke:#ced7dc}.jobsAtTheFamilyCourt-cls-7{fill:#547385}.jobsAtTheFamilyCourt-cls-3,.jobsAtTheFamilyCourt-cls-5{fill:none}.jobsAtTheFamilyCourt-cls-3{stroke:#ebeff1}.jobsAtTheFamilyCourt-cls-4{fill:#ebeff1;stroke:#0b3650}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <path
        d="M0 0h198v214.4H0z"
        style={{
          fill: '#0b3650',
        }}
      />
      <path
        d="M77.863 86.23 56.567 66.041 35.272 86.23h-6.614v87.336h84.155V86.23h-34.95z"
        style={{
          fill: '#f8b640',
        }}
      />
      <path
        d="M134.119 158.503h17.366v16.024h-17.366z"
        className="jobsAtTheFamilyCourt-cls-7"
      />
      <path
        d="M152.404 161.528c4.007 0 6.424-1.435 6.424-1.435l8.995-5.791-1.951-8.184c0-6.458-13.003-11.936-18.523-13.962a6.424 6.424 0 0 1-4.585 1.921h.076a6.424 6.424 0 0 1-4.585-1.921c-5.519 2.026-9.483 7.504-9.483 13.962V157.1s.421 4.429 4.429 4.429"
        className="jobsAtTheFamilyCourt-cls-7"
      />
      <circle
        cx={143.23}
        cy={122.011}
        r={6.627}
        style={{
          fill: '#547385',
          stroke: '#ced7dc',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '3.1px',
        }}
      />
      <path
        d="M133.438 158.543v16.023M138.575 131.387a6.603 6.603 0 0 0 9.483.067s18.218 6.995 18.218 20.099M152.311 158.543v16.023M142.802 164.299v10.267M138.374 131.454c-5.675 2.083-9.75 7.715-9.75 14.355V157.1s.507 4.429 4.577 4.429"
        className="jobsAtTheFamilyCourt-cls-5"
      />
      <path d="M112.813 87.01v86.557" className="jobsAtTheFamilyCourt-cls-3" />
      <path
        d="M41.952 173.568v-31.664c0-7.658 6.576-13.923 14.614-13.923h.001c8.038 0 14.615 6.265 14.615 13.923v31.664M86.083 102.995h12.031v17.016H86.083V108.2M86.083 136.545h12.031v21.016H86.083V141.75M50.552 98.368h12.031v17.016H50.552v-12.389"
        className="jobsAtTheFamilyCourt-cls-4"
      />
      <path d="M28.658 174.566h91.831" className="jobsAtTheFamilyCourt-cls-3" />
      <path
        d="M28.658 86.23h6.614l21.296-20.189L77.863 86.23h45.039L97.819 46.052H28.658"
        style={{
          fill: '#0b3650',
          stroke: '#ebeff1',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '3.1px',
        }}
      />
      <path
        d="M157.702 154.302h17.99v12.577h-17.99z"
        style={{
          fill: '#fdebcc',
          stroke: '#f8b640',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '3.1px',
        }}
      />
    </g>
  </svg>
)

export default JobsAtTheFamilyCourt
