// @flow
import * as React from 'react'

const HowToContactUs = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.howToContactUs-cls-1{stroke:#ebeff1}.howToContactUs-cls-1,.howToContactUs-cls-2{fill:none;stroke-width:3.1px;stroke-linecap:round;stroke-linejoin:round}.howToContactUs-cls-6{fill:#f59d00}.howToContactUs-cls-2{stroke:#ced7dc}.howToContactUs-cls-7{fill:#0b3650}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path d="M0 0h197.7v214.2H0z" className="howToContactUs-cls-7" />
        <path d="M.1.3H198v213.9H.1z" className="howToContactUs-cls-7" />
        <path
          d="M111.1 126.1V171H37.3v-46l37.4 31.6 36.4-30.5z"
          className="howToContactUs-cls-6"
        />
        <path
          d="M76.9 97.8c-1.2-.9-3.1-.9-4.3 0l-35 27.1c-.2.1-.3.3-.3.5v43.9c0 .8.7 1.5 1.5 1.5h71.8c.8 0 1.5-.7 1.5-1.5v-43.9c0-.2 0-.4-.3-.5l-35-27.1h.1Z"
          className="howToContactUs-cls-2"
        />
        <path
          d="M48.4 129.2v-21.5h52.7v22.5"
          style={{
            stroke: '#0b3650',
            strokeWidth: '7.2px',
            fill: '#0b3650',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          d="M101.1 134.5v-26.8H48.4v26.8l26.3 22.1 26.4-22.1z"
          style={{
            fill: '#3c5f73',
          }}
        />
        <path
          d="M48.4 127.1v-19.4h52.7v19.4"
          className="howToContactUs-cls-1"
        />
        <path
          d="m111.1 126.1-34.3 28.8c-1.1 1-3 1-4.2 0L38 125.8"
          className="howToContactUs-cls-2"
        />
        <path
          d="M80.4 124.8c1.1 4.4-2.8 8.2-7.1 7.1-2.1-.5-3.7-2.2-4.2-4.2-1.1-4.4 2.8-8.2 7.1-7.1 2.1.5 3.7 2.2 4.2 4.2Z"
          className="howToContactUs-cls-1"
        />
        <path
          d="M87.1 126.3c0 4.1-1.2 5.9-3.2 5.9s-3.2-1.8-3.2-5.9"
          className="howToContactUs-cls-1"
        />
        <path
          d="M79.5 137.6c-1.5.6-3.1 1-4.8 1s-3.3-.3-4.8-1c-1.5-.6-2.8-1.5-3.9-2.6s-2-2.4-2.6-3.9-1-3.1-1-4.8.3-3.3 1-4.8c.6-1.5 1.5-2.8 2.6-3.9s2.4-2 3.9-2.6 3.1-1 4.8-1 3.3.3 4.8 1c1.5.6 2.8 1.5 3.9 2.6s2 2.4 2.6 3.9 1 3.1 1 4.8"
          className="howToContactUs-cls-1"
        />
        <path
          d="m43.6 165.7 23.3-18.6c4.6-3.7 11.2-3.7 15.8 0l23.3 18.6"
          className="howToContactUs-cls-6"
        />
        <path
          d="m43.6 165.7 23.3-18.6c4.6-3.7 11.2-3.7 15.8 0l23.3 18.6"
          className="howToContactUs-cls-2"
        />
        <path
          d="M99.9 64.5h0c-9.5 9.5-3.8 30.5 12.6 46.9s37.4 22.1 46.9 12.6c2.9-2.9 4.2-9.4 4.2-9.4.2-1.1-.4-2.3-1.4-2.7L145 105c-1-.4-2.4 0-3.2.7l-5 5c-1 .9-2.3 1.2-3.4.7 0 0-.2 0-.3-.1h-.1c-3.8-2.1-7.8-5.1-11.6-8.8-3.8-3.8-6.8-7.8-8.8-11.6v-.1s0-.2-.1-.3c-.5-1.1-.3-2.5.7-3.4l5-5c.8-.8 1.1-2.2.7-3.2L112 61.7c-.4-1-1.6-1.6-2.7-1.4 0 0-6.5 1.3-9.4 4.2Z"
          style={{
            fill: '#547385',
            stroke: '#ced7dc',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '3.1px',
          }}
        />
        <path
          d="m151.7 94.5 9.9-11.2h6.3c2 0 3.6-1.6 3.6-3.6V57.4c0-2-1.6-3.6-3.6-3.6h-32.5c-2 0-3.6 1.6-3.6 3.6v22.3c0 2 1.6 3.6 3.6 3.6h14.8"
          style={{
            fill: '#0b3650',
            stroke: '#ced7dc',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '3.1px',
          }}
        />
        <path
          d="M161.8 64.1h-20.4M161.8 73.1h-20.4"
          className="howToContactUs-cls-2"
        />
      </g>
    </g>
  </svg>
)

export default HowToContactUs
