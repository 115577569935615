// @flow
import * as React from 'react'

const AddressesAndOpeningHours = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.">
    <defs>
      <style>
        {
          '.addresses-opening-hours-cls-2,.addresses-opening-hours-cls-5,.addresses-opening-hours-cls-6{stroke-linecap:round;stroke-linejoin:round}.addresses-opening-hours-cls-2{stroke:#f8b640}.addresses-opening-hours-cls-2,.addresses-opening-hours-cls-5,.addresses-opening-hours-cls-6{stroke-width:3px}.addresses-opening-hours-cls-2,.addresses-opening-hours-cls-5{fill:#fff}.addresses-opening-hours-cls-6{fill:none}.addresses-opening-hours-cls-10{fill:#0b3650}.addresses-opening-hours-cls-5,.addresses-opening-hours-cls-6{stroke:#ced7dc}.addresses-opening-hours-cls-11{fill:#547385}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <path
        d="M0 .232h198v213.937H0z"
        className="addresses-opening-hours-cls-10"
      />
      <circle
        cx={63.183}
        cy={81.377}
        r={37.039}
        className="addresses-opening-hours-cls-10"
      />
      <path
        d="M76.744 105.294v50.639l31.952 15.275v-50.639l-31.952-15.275zM140.693 105.294v50.639l31.953 15.275v-50.639l-12.909-6.171-19.044-9.104z"
        className="addresses-opening-hours-cls-11"
      />
      <path
        d="M76.744 155.933v-50.639"
        style={{
          strokeWidth: '3.1px',
          stroke: '#ced7dc',
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="m160.622 115.415 12.024 5.154M76.744 105.294l31.952 15.275 10.79-5.154M172.646 171.208v-50.639M76.744 155.933l31.952 15.275 31.975-15.275 31.975 15.275M108.696 163.388v-36.255M140.678 149.369V139.16"
        className="addresses-opening-hours-cls-6"
      />
      <path
        d="M140.693 135.652a6.538 6.538 0 0 1-5.717-3.301l-14.532-25.17a3.324 3.324 0 0 1-.149-.291c-2.59-4.619-3.521-9.839-2.692-15.115 1.605-10.211 10.232-18.424 20.515-19.528a24.206 24.206 0 0 1 2.576-.138c12.892 0 23.381 10.488 23.381 23.381 0 3.974-1.029 7.905-2.979 11.389a3.17 3.17 0 0 1-.153.302l-14.532 25.17a6.535 6.535 0 0 1-5.717 3.301Z"
        style={{
          fill: '#f8b640',
        }}
      />
      <path
        d="M140.695 75.184c11.214 0 20.306 9.091 20.306 20.306 0 3.7-1.005 7.158-2.734 10.145l.014.008-14.532 25.17c-.679 1.175-1.866 1.763-3.054 1.763s-2.375-.588-3.054-1.763l-14.532-25.17.014-.008c-2.225-3.844-3.252-8.469-2.48-13.383 1.4-8.911 8.835-15.984 17.804-16.947a21.12 21.12 0 0 1 2.247-.121m.001-4.645c-.921 0-1.856.05-2.779.149-11.317 1.215-20.455 9.913-22.22 21.152-.89 5.669.098 11.277 2.858 16.252.067.141.14.28.218.416l13.904 24.082c1.649 2.856 4.721 4.629 8.019 4.629s6.37-1.774 8.019-4.629l13.903-24.082c.081-.14.155-.281.223-.424a25.297 25.297 0 0 0 3.168-12.232c0-13.958-11.355-25.313-25.313-25.313Z"
        className="addresses-opening-hours-cls-10"
      />
      <path
        d="M150.178 95.49a9.486 9.486 0 1 1-9.485-9.484"
        style={{
          stroke: '#0b3650',
          fill: 'none',
          strokeWidth: 3,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M160.999 95.49c0-11.955-10.331-21.497-22.553-20.185-8.969.963-16.404 8.036-17.804 16.947-.772 4.915.255 9.539 2.48 13.383l-.014.008 14.532 25.17c1.357 2.351 4.75 2.351 6.107 0l14.532-25.17-.014-.008a20.18 20.18 0 0 0 2.734-10.145Z"
        style={{
          fill: 'none',
          stroke: '#ebeff1',
          strokeWidth: 3,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M36.514 81.377h2.923M63.183 57.631v-2.923M89.852 81.377h-2.923M63.183 105.123v2.923"
        className="addresses-opening-hours-cls-2"
      />
      <path
        d="M70.7 117.653c-2.427.5-4.941.763-7.517.763-20.456 0-37.04-16.583-37.04-37.04s16.583-37.039 37.04-37.039 37.04 16.583 37.04 37.039c0 8.95-3.174 17.158-8.458 23.561"
        className="addresses-opening-hours-cls-6"
      />
      <path
        d="M67.297 77.264a5.817 5.817 0 1 1-8.226 0"
        style={{
          stroke: '#ced7dc',
          fill: '#0b3650',
          strokeWidth: 3,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M43.697 61.89 59.07 77.264M67.297 77.264l11.332-11.332"
        className="addresses-opening-hours-cls-5"
      />
    </g>
  </svg>
)

export default AddressesAndOpeningHours
