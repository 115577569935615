// @flow
import * as React from 'react'

const Names = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.names-cls-1,.names-cls-2{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:3.1px}.names-cls-1{stroke:#cfe0d5}.names-cls-2{stroke:#d4cbe0}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          d="M0 0h197.7v214.2H0z"
          style={{
            fill: '#0e642e',
          }}
        />
        <path
          d="M53.2 43.7h107.3v145.4H53.2z"
          style={{
            fill: '#3e8358',
          }}
        />
        <path d="M152.4 43.7h8.4v145.4H53.5v-7.9" className="names-cls-1" />
        <path
          d="M38.8 29h107.3v145.4H38.8z"
          style={{
            fill: '#5e4876',
          }}
        />
        <circle
          cx={73.3}
          cy={67.6}
          r={19.3}
          style={{
            fill: '#0e642e',
            stroke: '#cfe0d5',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '3.1px',
          }}
        />
        <path
          d="M73.8 48.4c-4.1 0-7.5 3.4-7.5 7.5s2.7 6 6 6 4.8-2.2 4.8-4.8M68.6 71.8c1.1 1.4 2.8 2.3 4.7 2.3s3.6-.9 4.7-2.3"
          className="names-cls-1"
        />
        <path d="M38.8 29h107.3v145.4H38.8z" className="names-cls-2" />
        <path
          d="M108.6 48.4h22.8v15.9h-22.8z"
          style={{
            fill: '#5e4876',
            stroke: '#d4cbe0',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '3.1px',
          }}
        />
        <path
          d="M108.6 79.8h22.8M64.5 121h56.1M64.5 136.5h56.1M64.5 151.9h56.1M58.3 105.6h69"
          className="names-cls-2"
        />
      </g>
    </g>
  </svg>
)

export default Names
