// @flow
import * as React from 'react'

const ChildSupport = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.child-support-cls-1{fill:#f2809d}.child-support-cls-2,.child-support-cls-4,.child-support-cls-6{stroke-linecap:round;stroke-linejoin:round}.child-support-cls-2,.child-support-cls-4{stroke-width:3px}.child-support-cls-2,.child-support-cls-4,.child-support-cls-8{fill:none}.child-support-cls-2{stroke:#cceef5}.child-support-cls-9{fill:#ea3361}.child-support-cls-10{fill:#4cc2de}.child-support-cls-4{stroke:#f8bfce}.child-support-cls-6{stroke:#00a9cf;stroke-width:8.8px;fill:#00a9cf}.child-support-cls-8{stroke:#36322e;stroke-miterlimit:10;stroke-width:2.7px}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          d="M0 0h197.7v214.2H0z"
          style={{
            fill: '#00a9cf',
          }}
        />
        <path
          d="M118.6 150.6H83.9c-.4 0-.6-.3-.6-.6V82.1c0-.4.3-.6.6-.6h41.3c.4 0 .6.3.6.6v45.3"
          className="child-support-cls-1"
        />
        <path d="M40.3 120.4h33.2V151H40.3z" className="child-support-cls-10" />
        <path
          d="M76.1 126.1c7.7 0 8.5-8.5 8.5-8.5v-21c0-12.3-7.6-22.8-18.1-26.7-2.2 2.3-5.3 3.7-8.8 3.7h.1c-3.4 0-6.5-1.4-8.8-3.7-10.5 3.9-18.1 14.3-18.1 26.7v21s.8 8.5 8.5 8.5"
          className="child-support-cls-10"
        />
        <circle
          cx={57.7}
          cy={50.6}
          r={12.7}
          style={{
            fill: '#4cc2de',
            stroke: '#cceef5',
            strokeWidth: 3,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          d="M39 120.4V151M78.7 77.1c-3.1-3.8-7.2-6.7-11.8-8.4-2.3 2.3-5.5 3.8-9 3.8s-6.8-1.5-9.1-3.9M75 120.4V151M56.9 131.4V151M48.4 68.7c-10.8 4-18.6 14.7-18.6 27.4v21.6s0 6.2 4.3 6.5"
          className="child-support-cls-2"
        />
        <path
          d="M140 153.1h-30.4c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5H140V153v.1Z"
          className="child-support-cls-9"
        />
        <path
          d="M140 153.1h-30.4c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5H140V153v.1Z"
          className="child-support-cls-4"
        />
        <path
          d="M101 153.1h29.4V144H101c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5v.1Z"
          className="child-support-cls-4"
        />
        <rect
          width={42.6}
          height={69.2}
          x={83.2}
          y={81.4}
          className="child-support-cls-1"
          rx={0.6}
          ry={0.6}
        />
        <path
          d="M104.5 100.9c8.3 0 15.1 6.8 15.1 15.1s-6.8 15.1-15.1 15.1-15.1-6.8-15.1-15.1 6.8-15.1 15.1-15.1Z"
          style={{
            stroke: '#f8bfce',
            fill: '#ea3361',
            strokeWidth: 3,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          d="M148.4 172.3c-5.1 0-10.1-1.9-14-5.7-3.8-3.8-4.8-8-4.8-13.1l-12.8-12.8c-2-2-2-5.3 0-7.3s5.3-2 7.3 0l8.7 8.7c2.8 2.8 6.6 4.2 10.3 4.2M152.4 184.4l-6.7-6.7 22.5-22.4 6.7 6.7"
          className="child-support-cls-6"
        />
        <path
          d="m152.4 184.4-6.7-6.7 22.5-22.4 6.7 6.7"
          style={{
            stroke: '#f7b2c4',
            fill: '#ea3361',
            strokeWidth: 3,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <g
          style={{
            isolation: 'isolate',
          }}
        >
          <text
            style={{
              isolation: 'isolate',
              fill: '#f8bfce',
              fontFamily: 'MuseoSansRounded-1000,&quot',
              fontSize: '12.2px',
            }}
            transform="rotate(-90 116.55 7.55)"
          >
            <tspan x={0} y={0}>
              {'KR'}
            </tspan>
          </text>
        </g>
        <path
          d="M144.3 146.3c-3.7 0-7.5-1.4-10.3-4.2l-8.7-8.7c-2-2-5.3-2-7.3 0s-2 5.3 0 7.3l12.8 12.8c0 5.1 1 9.2 4.8 13.1 3.8 3.8 8.9 5.8 14 5.7l16-16-22.5-25.8s-2.5-5.4-11.5-4.7h-1.2v6.1l13.9 14.5v-.1Z"
          className="child-support-cls-9"
        />
        <path
          d="M143.2 146.3c-3.7 0-7.5-1.4-10.3-4.2l-8.7-8.7c-2-2-5.3-2-7.3 0s-2 5.3 0 7.3l12.8 12.8c0 5.1 1 9.2 4.8 13.1 3.8 3.8 8.9 5.8 14 5.7M164.8 155.9 141.4 130c-2-2.2-4.9-3.5-7.9-3.5h-2"
          className="child-support-cls-4"
        />
        <path
          d="M111.2 81.4c0 8.1 6.5 14.6 14.6 14.6V81.4h-14.6ZM97.8 81.4c0 8.1-6.5 14.6-14.6 14.6V81.4h14.6ZM97.8 150.3c0-8.1-6.5-14.6-14.6-14.6v14.6h14.6Z"
          className="child-support-cls-9"
        />
        <path
          d="M111.2 81.4c0 8.1 6.5 14.6 14.6 14.6M97.8 81.4c0 8.1-6.5 14.6-14.6 14.6"
          className="child-support-cls-4"
        />
        <path
          d="M118.6 150.6H83.9c-.4 0-.6-.3-.6-.6V82.1c0-.4.3-.6.6-.6h41.3c.4 0 .6.3.6.6v45.3"
          className="child-support-cls-4"
        />
        <path
          d="M97.8 150.6c0-8.1-6.5-14.6-14.6-14.6"
          className="child-support-cls-4"
        />
      </g>
    </g>
  </svg>
)

export default ChildSupport
