// @flow
import * as React from 'react'

const MeetingsAtTheFamilyCourt = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.meetings-court-cls-1,.meetings-court-cls-2{fill:#547385}.meetings-court-cls-1,.meetings-court-cls-2,.meetings-court-cls-3,.meetings-court-cls-4{stroke-linecap:round;stroke-linejoin:round;stroke-width:3.1px}.meetings-court-cls-1,.meetings-court-cls-4{stroke:#ced7dc}.meetings-court-cls-2,.meetings-court-cls-3{stroke:#ebeff1}.meetings-court-cls-3{fill:#f59d00}.meetings-court-cls-4{fill:none}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <path
        d="M-.294.232H198v213.937H-.294z"
        style={{
          fill: '#0b3650',
        }}
      />
      <circle
        cx={97.883}
        cy={60.941}
        r={10.916}
        className="meetings-court-cls-3"
      />
      <path
        d="M122.084 101.539c0-11.049-6.848-20.41-16.346-23.754-1.986 2.065-4.764 3.363-7.855 3.363a10.876 10.876 0 0 1-7.765-3.253c-9.347 3.432-16.06 12.708-16.06 23.645"
        className="meetings-court-cls-3"
      />
      <path
        d="M49.679 81.9c6.078 0 11.007-4.888 11.007-10.918s-4.928-10.918-11.007-10.918-11.007 4.888-11.007 10.918S43.6 81.9 49.679 81.9ZM29.774 125.516l2.693-25.986c.623-7.777 5.281-13.675 15.323-12.962 2.946.209 5.908 1.687 7.796 4.119 2.661 3.408 4.017 10.826 5.354 14.421 1.106 2.975 5.678 2.959 5.678 2.959l9.504-.177c3.535-.008 6.595 1.54 6.962 4.949.355 3.298-2.184 4.487-5.498 4.934 0 0-15.626.893-19.977-.109l-.342 7.852"
        className="meetings-court-cls-1"
      />
      <path
        d="m49.591 109.488 6.956 7.689c.1.154.35.286.72.398"
        className="meetings-court-cls-4"
      />
      <path
        d="M148.606 81.9c-6.078 0-11.007-4.888-11.007-10.918 0-6.029 4.928-10.918 11.007-10.918 6.078 0 11.007 4.888 11.007 10.918 0 6.03-4.928 10.918-11.007 10.918ZM168.511 125.516l-2.693-25.986c-.623-7.777-5.281-13.675-15.323-12.962-2.946.209-5.908 1.687-7.796 4.119-2.661 3.408-4.017 10.826-5.354 14.421-1.106 2.975-5.678 2.959-5.678 2.959l-9.504-.177c-3.535-.008-6.595 1.54-6.962 4.949-.355 3.298 2.184 4.487 5.498 4.934 0 0 15.626.893 19.977-.109l.342 7.852"
        className="meetings-court-cls-1"
      />
      <path
        d="m148.694 109.488-6.956 7.689c-.1.154-.35.286-.72.398"
        className="meetings-court-cls-4"
      />
      <path
        d="M127.246 137.647h7.25l-.505-12.776H64.142l-.361 12.776H70.5"
        className="meetings-court-cls-2"
      />
      <path
        d="m76.095 137.507-7.2 39.188 7.006 1.165 7.415-40.353M114.715 137.507l7.415 40.353 7.006-1.165-7.2-39.188"
        className="meetings-court-cls-2"
      />
      <path
        d="M88.276 137.647h21.037"
        style={{
          fill: '#0b3650',
          stroke: '#ebeff1',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '3.1px',
        }}
      />
    </g>
  </svg>
)

export default MeetingsAtTheFamilyCourt
