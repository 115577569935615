// @flow
import * as React from 'react'

const FuturePowerOfAttorney = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.future-power-1,.future-power-2,.future-power-3,.future-power-4{fill:none;stroke:#d4cbe0}.future-power-1,.future-power-2,.future-power-3,.future-power-4,.future-power-6{stroke-linecap:round}.future-power-1,.future-power-2,.future-power-6{stroke-linejoin:round}.future-power-1,.future-power-4{stroke-width:3.5px}.future-power-2,.future-power-3,.future-power-6{stroke-width:3.1px}.future-power-7{fill:#5e4876}.future-power-3,.future-power-4{stroke-miterlimit:10}.future-power-6{fill:#3e8358;stroke:#b7d1c0}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          d="M0 0h197.7v214.2H0z"
          style={{
            fill: '#0e642e',
          }}
        />
        <circle
          cx={153.7}
          cy={87.6}
          r={11.8}
          style={{
            strokeMiterlimit: 10,
            fill: '#5e4876',
            strokeWidth: '3.1px',
            stroke: '#d4cbe0',
          }}
        />
        <path
          d="M179 130.4c0-11.8-7.2-21.8-17.3-25.5-2.1 2.2-5.1 3.5-8.4 3.5h.1c-3.3 0-6.2-1.3-8.4-3.5-2.8 1-5.3 2.5-7.6 4.4-5.9 5-9.7 12.6-9.7 21.1"
          className="future-power-7"
        />
        <path
          d="M179 130.4c0-11.8-7.2-21.8-17.3-25.5-2.1 2.2-5.1 3.5-8.4 3.5h.1c-3.3 0-6.2-1.3-8.4-3.5-2.8 1-5.3 2.5-7.6 4.4"
          className="future-power-3"
        />
        <path
          d="M129.2 123.1v20H69.1V67h60.1v28.8"
          className="future-power-7"
        />
        <path
          d="M83 82.2h32.2M83 93.9h32.2M83 105h32.2"
          className="future-power-4"
        />
        <path
          d="M115.2 123.1c-2 0-2-3.8-4-3.8s-2 3.8-4 3.8-2-3.8-4-3.8-2 3.8-4 3.8-2-3.8-4-3.8-2 3.8-4 3.8-2-3.8-4-3.8-2 3.8-4 3.8"
          className="future-power-3"
        />
        <path
          d="M129.2 91.6V67H69.1v49.2M86.7 143.1h42.5V91.6"
          className="future-power-1"
        />
        <circle cx={49.8} cy={98.7} r={13.9} className="future-power-6" />
        <path
          d="M79.6 149.1c0-13.9-8.5-25.7-20.4-30-2.5 2.5-6 4.1-9.9 4.1h.2c-3.9 0-7.3-1.6-9.9-4.1-11.9 4.4-20.4 16.1-20.4 30"
          className="future-power-6"
        />
        <path
          d="M146.7 158s-2.6 8.7-11.2 12.1l-11.5.7h-6.8v6.6l-14-12.9 14-12.1v5.6h29.6"
          className="future-power-7"
        />
        <path
          d="M128.4 170.8h-11.2l-.1 6.5-14-12.9 14-12.9v6.5h13.3M128.4 170.8c10.7 0 19.4-8.7 19.4-19.4"
          className="future-power-2"
        />
      </g>
    </g>
  </svg>
)

export default FuturePowerOfAttorney
