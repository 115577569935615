// @flow
import * as React from 'react'

const PaymentOfFee = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.payment-fee-cls-4{stroke:#ced7dc}.payment-fee-cls-4,.payment-fee-cls-5{stroke-linecap:round;stroke-linejoin:round;stroke-width:3.5px}.payment-fee-cls-4,.payment-fee-cls-5,.payment-fee-cls-8{fill:none}.payment-fee-cls-8{stroke-width:3.8px;stroke:#36322e;stroke-miterlimit:10}.payment-fee-cls-5{stroke:#0b3650}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          d="M0 0h197.7v214.2H0z"
          style={{
            fill: '#0b3650',
          }}
        />
        <rect
          width={55.6}
          height={84.7}
          x={41.1}
          y={38.1}
          rx={2.4}
          ry={2.4}
          style={{
            fill: '#f59d00',
          }}
        />
        <path
          d="M53.5 39.9h14.1v83.4H53.5z"
          style={{
            fill: '#fdebcc',
          }}
        />
        <path
          d="M96.6 98.2s12.9-2.5 20.3 5.6c7.3 8.1 33.9 37.7 33.9 37.7l-23.4 24.4s-18.5-7.7-21.7-12.5c-3.1-4.8-4.3-4.8-4.3-4.8H72.3s-6.3-1.2-6.3-6.3 2.8-6.3 2.8-6.3h-8.4s-6.3.5-6.3-6.3 6.3-6.3 6.3-6.3H87l-6.7-6.7s-1.7-4.4 1.1-6.9 5.1-5.9 9-2.7c3.9 3.2 6.2 5.5 6.2 5.5V98.2Z"
          style={{
            fill: '#547385',
          }}
        />
        <path
          d="m149.5 139.9-32.6-36.1c-2.8-3.1-6.8-4.9-11-4.9h-4.5M72.4 135.9c-3.5 0-6.3 2.8-6.3 6.3s2.8 6.3 6.3 6.3h29.1"
          className="payment-fee-cls-4"
        />
        <path d="M81.5 53.3v14.8" className="payment-fee-cls-5" />
        <path
          d="M126.6 162.8c-7.1 0-14.2-2.6-19.5-8-5.4-5.4-6.8-11.2-6.7-18.3l-17.9-17.9c-2.8-2.8-2.8-7.4 0-10.2 2.8-2.8 7.4-2.8 10.2 0l12.2 12.2c4 4 9.2 5.9 14.4 5.9"
          className="payment-fee-cls-4"
        />
        <path
          d="m132.2 179.7-9.4-9.3 31.4-31.4 9.3 9.4"
          style={{
            strokeWidth: '12.3px',
            fill: '#0b3650',
            stroke: '#0b3650',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          d="m132.2 179.7-9.4-9.3 31.4-31.4 9.3 9.4"
          style={{
            strokeWidth: '3.5px',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            stroke: '#ced7dc',
            fill: '#30556a',
          }}
        />
        <path
          d="M53.5 38.1v84.7M67.6 38.1v84.7"
          className="payment-fee-cls-5"
        />
        <path
          d="M52.2 122.8h-8.5c-1.5 0-2.6-1.2-2.6-2.6V40.7c0-1.5 1.2-2.6 2.6-2.6H94c1.5 0 2.6 1.2 2.6 2.6v62.7"
          className="payment-fee-cls-8"
        />
        <path
          d="M60.4 135.9h38.4l-11.7-12.7H60.5c-3.5 0-6.3 2.8-6.3 6.3s2.8 6.3 6.3 6.3l-.1.1Z"
          className="payment-fee-cls-4"
        />
      </g>
    </g>
  </svg>
)

export default PaymentOfFee
