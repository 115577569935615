// @flow
import * as React from 'react'

const AnnualReports = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.annualReports-cls-3{fill:none;stroke:#ced7dc;stroke-linecap:round;stroke-linejoin:round}.annualReports-cls-4{fill:#ced7dc}.annualReports-cls-3{stroke-width:3.1px}.annualReports-cls-5{fill:#f8b640}.annualReports-cls-6{fill:#0b3650}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path d="M0 0h197.7v214.2H0z" className="annualReports-cls-6" />
        <path
          d="M54.1 46.5h103.6v140.4H54.1z"
          style={{
            fill: '#3c5f73',
          }}
        />
        <path
          d="M149.8 46.5h8.1v140.3H54.3v-7.6"
          className="annualReports-cls-3"
        />
        <path d="M40.4 32.3H144v140.4H40.4z" className="annualReports-cls-6" />
        <path
          d="M143.8 172.7H40.2V32.3h103.6v140.4M58.9 111.4h66.6M58.9 129.5h66.6M58.9 147.6h66.6"
          className="annualReports-cls-3"
        />
        <circle cx={73.6} cy={71.2} r={15.9} className="annualReports-cls-5" />
        <path
          d="M73.6 55.3c8.8 0 15.9 7.1 15.9 15.9H73.6V55.3Z"
          style={{
            fill: '#9dafb9',
          }}
        />
        <path
          d="M89.4 66.1c2 12.1-7.1 21-15.9 21V71.2l15.9-5.1Z"
          style={{
            fill: '#fdebcc',
          }}
        />
        <circle cx={73.6} cy={70.5} r={15.9} className="annualReports-cls-3" />
        <circle cx={73.6} cy={70.5} r={6.6} className="annualReports-cls-6" />
        <path
          d="M99.2 68.1h5.8v16.6h-5.8zM119.5 68.1h5.8v16.6h-5.8zM109.3 72.4h5.8v12.3h-5.8z"
          className="annualReports-cls-5"
        />
        <path
          d="m103.2 62.4 8.9 4.3 10.2-5.7"
          style={{
            fill: 'none',
            stroke: '#ced7dc',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 2,
          }}
        />
        <circle cx={102.7} cy={61.5} r={3.7} className="annualReports-cls-4" />
        <circle cx={112.2} cy={66.7} r={3.7} className="annualReports-cls-4" />
        <circle cx={122.4} cy={60.2} r={3.7} className="annualReports-cls-4" />
        <path d="M96.6 84.9h31.3" className="annualReports-cls-3" />
      </g>
    </g>
  </svg>
)

export default AnnualReports
