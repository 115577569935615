// @flow
import * as React from 'react'

const PerformancePlans = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.performance-plans-cls-2{stroke:#c8c9d3;stroke-linecap:round;stroke-linejoin:round;stroke-width:3.1px;fill:none}.performance-plans-cls-3{fill:#0b3650}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path d="M-.2 0h198.4v215H-.2z" className="performance-plans-cls-3" />
        <path
          d="M51.4 49.8H155v140.4H51.4z"
          style={{
            fill: '#3a5069',
          }}
        />
        <path
          d="M155.2 144.6v45.6H51.7v-7.6M147.1 49.8h8.1v61.6"
          className="performance-plans-cls-2"
        />
        <path
          d="M37.7 35.7h103.6v140.4H37.7z"
          className="performance-plans-cls-3"
        />
        <path
          d="M141.1 155.8v20.3M141.1 176.1H37.5V35.7h103.6v89"
          className="performance-plans-cls-2"
        />
        <path
          d="m177.9 97.1-40.3 40.2h0l-6.4 15.1 15-6.4 40.3-40.3-8.6-8.6z"
          style={{
            stroke: '#c8c9d3',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '3.1px',
            fill: '#f59d00',
          }}
        />
        <path
          d="m171.4 103.5 13.2 13.2-15.8 15.9M49.3 55.3h53M49.3 64.8h53M49.3 99.9h53M49.3 109.4h53M49.3 144.6h53M49.3 154h53M110.7 66.8V54h12.7M117.4 61.1l4.3 7 9.5-12.8M110.7 111.6V98.8h12.7M117.4 105.9l4.3 7 9.5-12.7M123.4 143.1h-12.7v12.7"
          className="performance-plans-cls-2"
        />
      </g>
    </g>
  </svg>
)

export default PerformancePlans
