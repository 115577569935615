// @flow
import * as React from 'react'

const PaternityCoMotherhood = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.paternity-co-motherhood-cls-1,.paternity-co-motherhood-cls-2{fill:none}.paternity-co-motherhood-cls-1,.paternity-co-motherhood-cls-2,.paternity-co-motherhood-cls-4{stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}.paternity-co-motherhood-cls-1,.paternity-co-motherhood-cls-4{stroke:#cceef5}.paternity-co-motherhood-cls-4,.paternity-co-motherhood-cls-6{fill:#4cc2de}.paternity-co-motherhood-cls-2{stroke:#ea3361}.paternity-co-motherhood-cls-7{fill:#00a9cf}.paternity-co-motherhood-cls-8{fill:#f7b2c4}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <path d="M0 0h198v214.2H0z" className="paternity-co-motherhood-cls-7" />
      <path
        d="M68.2 82.3c23.1 2.5 30 26.5 30 26.5s-3.3 3.9-5.2 1.3c-4.9-6.4-13.2-10-24.7-10.9M159.2 137.7c7.7 0 8.5-8.5 8.5-8.5v-21c0-12.4-7.6-22.8-18.1-26.7-2.2 2.3-5.3 3.7-8.8 3.7h.1c-3.4 0-6.5-1.4-8.8-3.7-10.6 3.9-18.1 14.4-18.1 26.7v4.2l1.9 1.9-1.9 2.1v12.7s.8 8.5 8.5 8.5M37.3 137.5h36.5v33.7H37.3z"
        className="paternity-co-motherhood-cls-6"
      />
      <path
        d="M118.1 133.1h36.5v38.1h-36.5zM55.5 86.6h.1c-3.3 0-6.4-1.4-8.5-3.6-10.3 3.8-17.7 14-17.7 26v20.4s.8 8.2 8.2 8.2h34c7.5 0 8.2-8.2 8.2-8.2V109c0-12-4.8-23.4-15.1-27.1-2.2 2.2-7.8 4.7-11.1 4.7h.1"
        className="paternity-co-motherhood-cls-6"
      />
      <path
        d="M111 158.7c-6 0-6.6-6.6-6.6-6.6v-16.4c0-9.7 5.9-17.9 14.2-20.9 1.8 1.8 4.2 2.9 6.9 2.9h-.1c2.7 0 5.1-1.1 6.9-2.9 8.3 3 14.2 11.2 14.2 20.9v16.4s-.6 6.6-6.6 6.6H111Z"
        style={{
          fill: '#00a9cf',
          stroke: '#00a9cf',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 3,
        }}
      />
      <circle
        cx={125.2}
        cy={104.7}
        r={14.4}
        className="paternity-co-motherhood-cls-7"
      />
      <path
        d="M114.4 154.2v18h22v-22.4h-22.5l.5 4.4z"
        className="paternity-co-motherhood-cls-8"
      />
      <circle
        cx={125.2}
        cy={104.7}
        r={8.2}
        style={{
          fill: '#f7b2c4',
          stroke: '#ea3361',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 3,
        }}
      />
      <path d="M124.7 158.6v12.6" className="paternity-co-motherhood-cls-2" />
      <circle
        cx={140.8}
        cy={64.4}
        r={12.2}
        className="paternity-co-motherhood-cls-4"
      />
      <path
        d="M159.2 137.5c7.6 0 8.4-8.4 8.4-8.4v-20.8c0-12.2-7.8-22.8-18.2-26.6-2.2 2.3-5.3 3.8-8.8 3.8s-6.5-1.4-8.7-3.6c-3.2 1.2-6.2 3-8.7 5.3M37.5 137.5c-7.6 0-8.4-8.4-8.4-8.4v-20.8c0-12.2 7.8-22.8 18.2-26.6 2.2 2.3 5.3 3.8 8.8 3.8s6.5-1.4 8.7-3.6M153 136.7v34.5"
        className="paternity-co-motherhood-cls-1"
      />
      <circle
        cx={56.3}
        cy={64.4}
        r={12.2}
        className="paternity-co-motherhood-cls-4"
      />
      <path
        d="M47.4 81.8c2.2 2.2 5.3 3.6 8.7 3.6s6.6-1.5 8.8-3.8c0 0 30.7 7 34.6 27.7M74.3 136.7v34.5M38.3 136.7v34.5M55.6 145.7v25.5"
        className="paternity-co-motherhood-cls-1"
      />
      <path
        d="M114.4 154.2c-1.9 0-1.7-4.3-1.7-4.3v-14.1c0-8.2-.8-15.1 6.2-17.6 1.5 1.5 3.5 2.4 5.8 2.4s4.3-.9 5.8-2.4c7 2.6 12 9.5 12 17.7v13.9s-.5 7-5.6 7"
        className="paternity-co-motherhood-cls-8"
      />
      <path
        d="M114.4 151.5v19.7M137.6 156.7c5.1 0 5.6-5.8 5.6-5.8v-14.4c0-8.5-5-15.7-12-18.3-1.5 1.6-3.5 2.5-5.8 2.5h0c-2.3 0-4.3-1-5.8-2.5 0 0-19.2 6.9-23.9-3.5"
        className="paternity-co-motherhood-cls-2"
      />
      <path
        d="M94.8 110.8s-8.6-12.4-23.5-10.6"
        className="paternity-co-motherhood-cls-1"
      />
    </g>
  </svg>
)

export default PaternityCoMotherhood
