// @flow
import * as React from 'react'

const Visitation = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.visitation-cls-2{fill:#ea3361}.visitation-cls-2,.visitation-cls-5,.visitation-cls-6{stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}.visitation-cls-2,.visitation-cls-5{stroke:#f8bfce}.visitation-cls-5{fill:none}.visitation-cls-6{fill:#4cc2de;stroke:#cceef5}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          d="M0 0h197.7v214.2H0z"
          style={{
            fill: '#00a9cf',
          }}
        />
        <path
          d="M140 169.2v-31.8c0-18.7-11.5-34.6-27.5-40.5-3.4 3.4-8.1 5.6-13.3 5.6h.2c-5.2 0-9.9-2.1-13.3-5.6-16 5.9-27.5 21.8-27.5 40.5v31.8"
          style={{
            fill: '#ea3361',
          }}
        />
        <circle cx={99.3} cy={71.7} r={19.2} className="visitation-cls-2" />
        <path
          d="M141.6 137.7c0-19.3-11.8-35.6-28.3-41.6-3.5 3.5-8.3 5.7-13.7 5.7s-10.3-2.3-13.8-5.9M85.3 96.1c-16.5 6-28.3 22.4-28.3 41.6"
          className="visitation-cls-5"
        />
        <path
          d="M89.4 170.4v-16.1c0-15.9-9.8-29.4-23.4-34.4-2.9 2.9-6.9 4.7-11.3 4.7h.2c-4.4 0-8.4-1.8-11.3-4.7-13.6 5-23.4 18.5-23.4 34.4v16.1"
          style={{
            strokeWidth: '4.9px',
            fill: '#00a9cf',
            stroke: '#00a9cf',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <circle cx={55.1} cy={110.8} r={11.9} className="visitation-cls-6" />
        <path
          d="M80.7 168.7v-14.8c0-11.9-7.3-22-17.5-25.7-2.2 2.2-5.1 3.5-8.4 3.5h.1c-3.3 0-6.3-1.4-8.4-3.5C36.3 131.9 29 142 29 153.9v14.8"
          className="visitation-cls-6"
        />
        <path
          d="M177.1 169.2v-14.9c0-15.9-9.8-29.4-23.4-34.4-2.9 2.9-6.9 4.7-11.3 4.7h.2c-4.4 0-7.2-2.7-10-5.6-13.6 5-24.6 19.4-24.6 35.3v14.9"
          style={{
            fill: '#00a9cf',
            stroke: '#00a9cf',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '.8px',
          }}
        />
        <circle cx={142.8} cy={110.8} r={11.9} className="visitation-cls-6" />
        <path
          d="M168.4 170.4v-16.5c0-11.9-7.3-22-17.5-25.7-2.2 2.2-5.1 3.5-8.4 3.5h.1c-3.3 0-6.3-1.4-8.4-3.5-10.2 3.7-17.5 13.8-17.5 25.7v16.5"
          className="visitation-cls-6"
        />
        <path
          d="M169.9 146.3c-3.3 3.3-8.6 3.3-11.8 0-3.3-3.3-3.3-8.6 0-11.8 3.3-3.3 6.5-1.2 9.7 2.1 3.3 3.3 5.4 6.5 2.1 9.7ZM27.6 146.1c3.3 3.3 8.6 3.3 11.8 0 3.3-3.3 3.3-8.6 0-11.8-3.3-3.3-6.5-1.2-9.7 2.1-3.3 3.3-5.4 6.5-2.1 9.7Z"
          className="visitation-cls-2"
        />
        <path
          d="M.1.1h197.5V214H.1z"
          style={{
            isolation: 'isolate',
            opacity: 0.5,
            stroke: '#bbb8b7',
            strokeMiterlimit: 10,
            fill: 'none',
          }}
        />
      </g>
    </g>
  </svg>
)

export default Visitation
