// @flow
import * as React from 'react'

const Restraint = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.restraint-cls-3{fill:#5e4876;stroke:#dfdae4}.restraint-cls-3,.restraint-cls-6{stroke-width:3.1px}.restraint-cls-3,.restraint-cls-6,.restraint-cls-7{stroke-linecap:round;stroke-linejoin:round}.restraint-cls-6,.restraint-cls-7{fill:none}.restraint-cls-10{fill:#0e642e}.restraint-cls-11{fill:#cfe0d5}.restraint-cls-6{stroke:#cfe0d5}.restraint-cls-7{stroke-width:2.528px;stroke:#0e642e}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <path d="M.26.232h197.48v213.937H.26z" className="restraint-cls-10" />
      <path
        d="M156.682 152.687H40.752c-.704 0-1.274-.57-1.274-1.274V83.916c0-.704.57-1.274 1.274-1.274h115.93c.704 0 1.274.57 1.274 1.274v67.497c0 .704-.57 1.274-1.274 1.274Z"
        style={{
          fill: '#3e8358',
        }}
      />
      <circle
        cx={98.717}
        cy={117.664}
        r={24.797}
        style={{
          fill: '#0e642e',
          stroke: '#e2ece6',
          strokeMiterlimit: 10,
          strokeWidth: '3.1px',
        }}
        transform="rotate(-45 98.717 117.663)"
      />
      <path
        d="M52.501 72.903H164.47a3.223 3.223 0 0 1 3.224 3.224v63.536"
        className="restraint-cls-6"
      />
      <path
        d="M62.239 63.165h111.743a3.45 3.45 0 0 1 3.45 3.45v63.31"
        className="restraint-cls-6"
      />
      <path
        d="M138.093 152.687c0-10.97 8.893-19.864 19.864-19.864"
        className="restraint-cls-7"
      />
      <path
        d="M138.093 82.641c0 10.97 8.893 19.864 19.864 19.864V82.641h-19.864Z"
        className="restraint-cls-10"
      />
      <path
        d="M157.956 102.505c-10.97 0-19.864-8.893-19.864-19.864"
        className="restraint-cls-6"
      />
      <path
        d="M157.956 132.823c-10.97 0-19.864 8.893-19.864 19.864h19.864v-19.864Z"
        className="restraint-cls-10"
      />
      <path
        d="M138.093 152.686c0-10.97 8.893-19.864 19.864-19.864"
        className="restraint-cls-6"
      />
      <path
        d="M59.341 152.686c0-10.97-8.893-19.864-19.864-19.864v19.864h19.864Z"
        style={{
          fill: '#e2ece6',
        }}
      />
      <path
        d="M39.478 132.823c10.97 0 19.864 8.893 19.864 19.864"
        className="restraint-cls-7"
      />
      <path
        d="M39.478 102.505c10.97 0 19.864-8.893 19.864-19.864H39.478v19.864Z"
        className="restraint-cls-10"
      />
      <path
        d="M59.341 82.641c0 10.97-8.893 19.864-19.864 19.864"
        className="restraint-cls-6"
      />
      <path
        d="M88.806 112.831c0-.708.359-1.062 1.078-1.062h1.308c.708 0 1.062.354 1.062 1.062v3.284h.915l1.781-3.578c.283-.512.708-.769 1.274-.769h1.34c.425 0 .708.12.85.359.142.24.114.545-.082.915l-2.353 4.363v.032l2.532 4.787c.207.382.24.692.099.932s-.425.359-.85.359h-1.536c-.566 0-.991-.256-1.274-.768l-1.781-3.709h-.915v3.414c0 .709-.354 1.062-1.062 1.062h-1.308c-.719 0-1.078-.354-1.078-1.062v-9.623ZM99.916 112.831c0-.708.359-1.062 1.078-1.062h3.187c.545 0 1.005.02 1.381.058s.694.101.956.188c.326.109.631.272.915.49.282.219.527.479.734.784.207.306.368.648.482 1.029.114.382.172.79.172 1.226 0 .294-.033.6-.099.915-.065.316-.163.621-.294.915s-.3.566-.507.817c-.207.25-.452.463-.734.637v.033c.054.065.108.136.163.212a3.24 3.24 0 0 1 .36.54l1.471 2.614c.229.414.261.733.098.956-.163.224-.441.335-.833.335h-1.52c-.251 0-.485-.055-.703-.163-.218-.109-.402-.305-.555-.589l-1.487-2.875h-.817v2.564c0 .709-.354 1.062-1.062 1.062h-1.308c-.719 0-1.078-.354-1.078-1.062v-9.623Zm4.183 4.117c.414 0 .722-.095.924-.286.201-.19.302-.509.302-.955 0-.153-.027-.311-.081-.475-.055-.163-.202-.294-.441-.392a1.486 1.486 0 0 0-.417-.106 4.383 4.383 0 0 0-.498-.024h-.523v2.238h.735Z"
        className="restraint-cls-11"
      />
      <path
        d="M39.477 102.2V83.915c0-.704.57-1.274 1.274-1.274h115.93c.704 0 1.274.57 1.274 1.274v67.497c0 .704-.57 1.274-1.274 1.274H68.554"
        className="restraint-cls-6"
      />
      <ellipse
        cx={43.991}
        cy={124.292}
        rx={11.399}
        ry={8.696}
        style={{
          strokeWidth: '2.686px',
          stroke: '#0e642e',
          fill: '#0e642e',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M56.936 128.464a2.773 2.773 0 0 1-2.77-2.77c0-5.479-4.458-9.937-9.936-9.937s-9.937 4.458-9.937 9.937c0 1.527-1.242 2.77-2.77 2.77s-2.769-1.242-2.769-2.77c0-8.533 6.942-15.476 15.476-15.476s15.475 6.942 15.475 15.476a2.773 2.773 0 0 1-2.77 2.77Z"
        style={{
          fill: '#5e4876',
        }}
      />
      <path
        d="M44.23 111.769c7.678 0 13.925 6.247 13.925 13.925a1.22 1.22 0 1 1-2.44 0c0-6.334-5.152-11.486-11.486-11.486s-11.486 5.152-11.486 11.486a1.22 1.22 0 1 1-2.44 0c0-7.678 6.247-13.925 13.926-13.925m.001-3.1c-9.388 0-17.026 7.638-17.026 17.025 0 2.382 1.938 4.32 4.32 4.32s4.32-1.938 4.32-4.32c0-4.624 3.762-8.386 8.386-8.386s8.386 3.762 8.386 8.386c0 2.382 1.938 4.32 4.32 4.32s4.32-1.938 4.32-4.32c0-9.388-7.638-17.025-17.025-17.025Z"
        style={{
          fill: '#dfdae4',
        }}
      />
      <path
        d="M25.63 125.991h36.415v36.415H25.63z"
        className="restraint-cls-3"
      />
      <circle cx={43.838} cy={141.897} r={3.493} className="restraint-cls-3" />
      <path
        d="M43.838 151.919v-6.529"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '3.1px',
          stroke: '#dfdae4',
        }}
      />
    </g>
  </svg>
)

export default Restraint
