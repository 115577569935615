// @flow
import * as React from 'react'

const AboutTheFamilyCourt = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.about-family-court-cls-2{stroke:#ebeff1}.about-family-court-cls-2,.about-family-court-cls-3{stroke-linecap:round;stroke-linejoin:round;stroke-width:3.1px}.about-family-court-cls-2{fill:none}.about-family-court-cls-3{fill:#ebeff1;stroke:#0b3650}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          d="M0 0h197.7v214.2H0z"
          style={{
            fill: '#0b3650',
          }}
        />
        <path
          d="M153.1 175H40.6V87.7h35l21.3-20.2 21.3 20.2h34.9V175z"
          style={{
            fill: '#f8b640',
          }}
        />
        <path
          d="M153.1 88.5V175M40.6 175V88.5"
          className="about-family-court-cls-2"
        />
        <path
          d="M55.6 47.5h82.5l25.1 40.2h-45L96.9 67.5 75.6 87.7H30.5l25.1-40.2h0z"
          style={{
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '3.1px',
            stroke: '#ebeff1',
            fill: '#547385',
          }}
        />
        <path
          d="M82.3 175v-31.7c0-7.7 6.6-13.9 14.6-13.9h0c8 0 14.6 6.3 14.6 13.9V175M126.4 104.4h12v17.1h-12v-11.8M55.3 104.4h12.1v17.1H55.3v-11.8M126.4 138h12v21h-12v-15.8M55.3 138h12.1v21H55.3v-15.8M90.9 99.8h12v17h-12v-12.4"
          className="about-family-court-cls-3"
        />
        <path d="M32.9 176h127.8" className="about-family-court-cls-2" />
      </g>
    </g>
  </svg>
)
export default AboutTheFamilyCourt
