// @flow
import * as React from 'react'

const DivorceSeparation = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.divorce-separation-cls-2{stroke:#dfdae4}.divorce-separation-cls-2,.divorce-separation-cls-3{stroke-linecap:round;stroke-linejoin:round}.divorce-separation-cls-2,.divorce-separation-cls-5{fill:#5e4876}.divorce-separation-cls-2,.divorce-separation-cls-3{stroke-width:3.1px}.divorce-separation-cls-3{fill:none;stroke:#d4cbe0}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <path
        d="M0 0h198v214.4H0z"
        style={{
          fill: '#0e642e',
        }}
      />
      <path
        d="m115.6 36.4-6.1 11.4 4 .2-6.7 19.4c-.4.8.5 1.6 1.2 1.1l17.4-23.6-5.7-.3 4.9-6.6h0c6 2.9 10.1 9.1 10 16.2-.4 20.7-21.7 27.5-35.9 33.8-13.5-6.6-35-14.4-34.7-35.1.2-9.7 8.2-17.5 17.9-17.3 9.7.2 17.5 8.2 17.3 17.9.2-9 7.1-16.4 15.9-17.2h.3l.2.1Z"
        style={{
          fill: '#5e4876',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          stroke: '#dfdae4',
          strokeWidth: 3,
        }}
      />
      <path
        d="M138.1 154.2h30.2v27.9h-30.2z"
        className="divorce-separation-cls-5"
      />
      <path
        d="M170 159.5c7 0 7.7-7.7 7.7-7.7v-19.1c0-11.2-6.9-20.8-16.5-24.3-2 2.1-4.9 3.3-8 3.3h.1c-3.1 0-5.9-1.3-8-3.3-9.6 3.5-16.5 13.1-16.5 24.3v19.1s.7 7.7 7.7 7.7"
        className="divorce-separation-cls-5"
      />
      <circle
        cx={154}
        cy={90.7}
        r={11.5}
        className="divorce-separation-cls-2"
      />
      <path
        d="M137 154.3v27.9M171.5 159.7c7.2 0 7.9-7.9 7.9-7.9v-19.7c0-11.6-7.1-21.4-17-25-2.1 2.1-5 3.4-8.2 3.4s-6.2-1.4-8.3-3.6M169.8 154.3v27.9M153.3 164.3v17.9M145.6 107.1c-9.9 3.6-17 13.4-17 25v19.7s.9 7.7 8 7.7"
        className="divorce-separation-cls-3"
      />
      <path d="M29 154.2h30.2v27.9H29z" className="divorce-separation-cls-5" />
      <path
        d="M60.8 159.5c7 0 7.7-7.7 7.7-7.7v-19.1c0-11.2-6.9-20.8-16.5-24.3-2 2.1-4.9 3.3-8 3.3h.1c-3.1 0-5.9-1.3-8-3.3-9.6 3.5-16.5 13.1-16.5 24.3v19.1s.7 7.7 7.7 7.7"
        className="divorce-separation-cls-5"
      />
      <circle
        cx={44.8}
        cy={90.7}
        r={11.5}
        className="divorce-separation-cls-2"
      />
      <path
        d="M27.8 154.3v27.9M62.3 159.7c7.2 0 7.9-7.9 7.9-7.9v-19.7c0-11.6-7.1-21.4-17-25-2.1 2.1-5 3.4-8.2 3.4s-6.2-1.4-8.3-3.6M60.7 154.3v27.9M44.1 164.3v17.9M36.4 107.1c-9.9 3.6-17 13.4-17 25v19.7s.9 7.7 8 7.7"
        className="divorce-separation-cls-3"
      />
    </g>
  </svg>
)

export default DivorceSeparation
