// @flow
import * as React from 'react'

const CaseProcessingTimes = ({ style, ...rest }: Object) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 214.4">
    <defs>
      <style>
        {
          '.case-processing-times-cls-1,.case-processing-times-cls-2{fill:none}.case-processing-times-cls-1,.case-processing-times-cls-2,.case-processing-times-cls-4{stroke-linecap:round;stroke-width:3.1px}.case-processing-times-cls-1,.case-processing-times-cls-4{stroke:#ebeff1;stroke-miterlimit:10}.case-processing-times-cls-5{fill:#547385}.case-processing-times-cls-2{stroke:#e2e7ea;stroke-linejoin:round}.case-processing-times-cls-4,.case-processing-times-cls-6{fill:#f59d00}'
        }
      </style>
    </defs>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          d="M0 0h197.7v214.2H0z"
          style={{
            fill: '#0b3650',
          }}
        />
        <path
          d="M49.1 41.1h91v123.3h-91z"
          className="case-processing-times-cls-5"
        />
        <path
          d="M133.2 41.1h7.1v123.3h-91v-6.7"
          className="case-processing-times-cls-2"
        />
        <path
          d="M37.1 28.7h91V152h-91z"
          className="case-processing-times-cls-5"
        />
        <path
          d="M93.3 152H36.9V28.7h91v90.9M53.3 52.8h58.5M53.3 68.7h58.5M53.3 84.6h58.5M53.3 100.5h58.5M53.3 116.4h58.5"
          className="case-processing-times-cls-2"
        />
        <rect
          width={62.9}
          height={53.9}
          x={103.1}
          y={130}
          className="case-processing-times-cls-6"
          rx={5.7}
          ry={5.7}
        />
        <path
          d="M157.8 144.9h8.2M103.1 144.9h45"
          className="case-processing-times-cls-1"
        />
        <rect
          width={7.5}
          height={12}
          x={115.9}
          y={124}
          className="case-processing-times-cls-4"
          rx={2}
          ry={2}
        />
        <rect
          width={7.5}
          height={12}
          x={145.8}
          y={124}
          className="case-processing-times-cls-4"
          rx={2}
          ry={2}
        />
        <path
          d="M153.3 130h8.2c2.5 0 4.5 2 4.5 4.5v44.9c0 2.5-2 4.5-4.5 4.5h-26M123.3 130h22.5M103.1 155.3v-20.9c0-2.5 2-4.5 4.5-4.5h8.2"
          className="case-processing-times-cls-1"
        />
        <circle
          cx={113.6}
          cy={176.4}
          r={16.5}
          className="case-processing-times-cls-6"
        />
        <circle
          cx={113.6}
          cy={176.4}
          r={16.5}
          className="case-processing-times-cls-1"
        />
        <path
          d="M113.6 167.4v9h7.5"
          style={{
            strokeLinejoin: 'round',
            stroke: '#ebeff1',
            strokeLinecap: 'round',
            strokeWidth: '3.1px',
            fill: 'none',
          }}
        />
      </g>
    </g>
  </svg>
)

export default CaseProcessingTimes
